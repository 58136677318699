import { equalTo, get, orderByChild, query as rbaseQuery, ref as rbaseRef } from "firebase/database";
import React, { useEffect, useState } from "react";
//import { useNavigate } from 'react-router-dom';
import LoadSpinner from "./LoadSpinner";
//import { initializeApp } from "firebase/app";
import {
  addCustomerExternalInputObj,
  addStoreBrandRequestInputObj,
  database,
  decodeFbAuthError,
  fetchAndActivate,
  getAuth,
  getValue,
  remoteConfig,
  sendEmailVerification,
  signInWithEmailAndPassword,
  styleProxyMap,
  useNavigate
} from "../Utils";

//import { getFunctions, httpsCallable } from "firebase/functions";

import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxMessagePromptWithMultipleOps from "./ModalBoxMessagePromptWithMultipleOps";
import ModalBoxMessagePromptWithOperation from "./ModalBoxMessagePromptWithOperation";
//import { check } from "../../functions";
import { sendPasswordResetEmail } from "firebase/auth";
import { useLocation } from "react-router-dom";
import BtnNormal from "./BtnNormal";
import { getFbStorageFile } from "./DbHelperFns";
//import { firebaseConfig } from '../firebaseWebConfiguration';
//import {getAuth,signInWithEmailAndPassword} from "firebase/auth";

export default function LoginScreenStudent(props) {
  const auth = getAuth();

  const location = useLocation()
  const instituteMap = {...location.state}

  console.log(instituteMap)


  const [instituteShortName,setInstituteShortName] = useState("")

//8AuW5zNfajQs2U6HY8WhmZbGeHk1
  // document.addEventListener('DOMContentLoaded',()=>{
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const shortName = urlParams.get('go');
  //   // const actionCode = urlParams.get('oobCode');
  //   // const continueUrl = urlParams.has('continueUrl') ? urlParams.get('continueUrl') : "";

  //   console.log(shortName)

  //   setInstituteShortName("8AuW5zNfajQs2U6HY8WhmZbGeHk1")

  // },false);

  const navigate = useNavigate();
  const [showPromptOp, setShowPromptOp] = useState(false);
  const [showPromptOp2, setShowPromptOp2] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showSpinner,setShowSpinner]=useState(false);
  const [remoteValMap,setRemoteValMap] = useState({})
  const [loginBackFile,setLoginBackFile] = useState(null)
  const [logoFile,setLogoFile] = useState(null)

  useEffect((()=>{

    const urlSearchString = window.location.search;

    const params = new URLSearchParams(urlSearchString);
  
    const shortName = params.get('go');

    console.log(shortName);

    //const val = getValue(remoteConfig,"check_email_verification")

    (async ()=>await functions("initializeData",{instituteShortName:shortName}))()

    fetchAndActivate(remoteConfig).then((valA)=>{
      console.log("remoteConfig Value",getValue(remoteConfig,"check_email_verification").asBoolean())

      const remoteValue = getValue(remoteConfig,"check_email_verification").asBoolean()
      
      setRemoteValMap({
        checkEmailVerification:remoteValue
      })

    }).catch((err)=>{
      console.log("error in remoteFetch",err)
    })



  }),[])



  async function functions(fnName,data){
    
    switch(fnName){
      case "initializeData":{

        setShowSpinner(true)
        try{

        let instituteUid = ""

        if(data.instituteShortName){

         const queryRef = rbaseQuery(rbaseRef(database,'institutes'),orderByChild('instituteShortName'),equalTo(data.instituteShortName));
         const snapshot = await get(queryRef)
         console.log(snapshot.val())
          
         instituteUid = Object.keys(snapshot.val())[0];

        }else{
          instituteUid = instituteMap.instituteUid
        }
        
    
        console.log(instituteUid)

        const instituteLoginBackFile = await getFbStorageFile(`institutes/${instituteUid}`,`loginBack.jpg`);
        const instituteLogoFile = await getFbStorageFile(`institutes/${instituteUid}`,`logo.svg`);

        setLoginBackFile(instituteLoginBackFile)
        setLogoFile(instituteLogoFile)

        // const selectionsFromDb = await getDbInpOptions(dbSelectionSchemeMap,{})


        setShowSpinner(false)

        // console.log(selectionsFromDb)

        // const labelKeyName = dbSelectionSchemeMap.selectOptionsLabelKey
        // const valueKeyName = dbSelectionSchemeMap.selectOptionsValueKey

        // const finalSelectionMaps = selectionsFromDb.map((selectionMap)=>{return {label:selectionMap[labelKeyName],value:selectionMap[valueKeyName]}})

        // setInstituteOptionMaps(finalSelectionMaps)

      } catch (err) {
        console.log(err)
        console.log('Error Initializing Page Data');
      }
      }
      break;
    }

  }

  function handleChange(event) {
    if (event.target.name == "email") {
      setEmail(`${event.target.value}@ace-campus.com`);
    } else {
      setPassword(event.target.value);
    }
  }

  async function signIn(email, password) {

    setShowSpinner(true)
    const dialogTitle = "Sign In"

    try {

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const idTokenResult = await auth.currentUser.getIdTokenResult()
      const userRole = idTokenResult.claims.role;

      const allowedRoles = ["Institute","Student","Principal","Super Admin","Master"]
      const restrictedRoles = ["Employee","Customer"]

      if(allowedRoles.includes(userRole)){
        
      if(remoteValMap.checkEmailVerification){

    
        if(auth.currentUser.emailVerified){

          navigate(props.userMode=="store"?"/dashboard":"/dashboard-admin", { replace: true });

        }else{

          const opBtnLabels = ["Send Verification Link","Change Email"]
          const opBtnAlertLevels = ["low","high"]
          const opSuccessMsgs  = ["Password reset link sent to your email",""]

          const obj = {
            userUid:auth.currentUser.uid,
            userEmail:auth.currentUser.email,
          }

          const message = "Your email id is not verified, please kindly verify your email-id through the verification link that was sent after sign up, alternatively you can initiate a verification link by clicking the Send Verification button below. In case your email id is wrong, you can request for a change of email id"

          const verifMailFn = async () => {
            // const actionCodeSettings = {
            //   url:process.env.REACT_APP_FB_CONTINUE_URL,
            //   ios:{
            //     bundleid:"com.ncodemedia.hockeycaddyDev"
            //   },
            //   android:{
            //     packageName:"com.ncodemedia.hockeycaddy",
            //     installApp:true
            //   },
            //   handleCodeInApp:true,
            // }
            await sendEmailVerification(auth.currentUser)
          }

          const changeEmailFn = async () => {
            navigate('/changeEmail',{state:obj})
          }


          setPromptMap({
            status:false,
            title:dialogTitle,
            message:message,
            opBtnLabels:opBtnLabels,
            opBtnAlertLevels:opBtnAlertLevels,
            opSuccessMsgs:opSuccessMsgs,
            opFns: [verifMailFn,changeEmailFn]
        })

        setShowPromptOp2(true);

      }

      }else{
        navigate(props.userMode=="store"?"/dashboard":"/dashboard-admin", { replace: true });
      }

      }else{
        if(restrictedRoles.includes(userRole)){
          displayPrompt(dialogTitle,"It seems your user role is not applicable to this platform. If you are a Customer please download and install the 'Hockey Caddy' App or if your a store employee please use the 'Hockey Caddy Service' App. These apps can be found in the Apple App Store or Android Playstore based on your mobile platform.")
        }else{
          displayPrompt(dialogTitle,"Invalid user role, please inform support")
        }
      }

      setShowSpinner(false)

    } catch (error) {

  
      const opBtnLabels = ["Send Password Reset Link"]
      const opBtnAlertLevels = ["low"]
      const opSuccessMsgs  = ["Password reset link sent to your email"]

      const passwordResetFn = async () => {
        const actionCodeSettings = {
          // url:process.env.REACT_APP_FB_CONTINUE_URL,
          url:"https://skate-smith-pro.web.app/",
          ios:{
            bundleid:"com.ncodemedia.hockeycaddyDev"
          },
          android:{
            packageName:"com.ncodemedia.hockeycaddy",
            installApp:true
          },
          handleCodeInApp:false,
        }
        await sendPasswordResetEmail(auth,email,actionCodeSettings)
      }

      const opFns = [passwordResetFn]

      if(decodeFbAuthError(error.code) == "No User Found"){

        displayPrompt(dialogTitle,decodeFbAuthError(error.code))

      }else{
        setPromptMap({
          status:false,
          title:dialogTitle,
          message:decodeFbAuthError(error.code),
          opBtnLabels:opBtnLabels,
          // opBtnAlertLevels:opBtnAlertLevels,
          opSuccessMsgs:opSuccessMsgs,
          opFns:opFns
        })

        displayPrompt("Wrong Credentials","Please check the username and password",false)
  
        //setShowPromptOp2(true);
      }
      setShowSpinner(false)

    }
  }



  function displayPrompt(title,message,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title,
      message:message
    })
  }

  function btnClickFn(btnName){
    setShowSpinner(true)
    if(btnName=="resetPassword"){
      navigate("/resetPassword")
    }else if(btnName=="signup"){
      navigate("/signup",{state:addStoreBrandRequestInputObj})
    }else if(btnName=="signupCustomer"){
      navigate("/signup",{state:addCustomerExternalInputObj})
    }
  }


  return (
    <div>
      
    <div className="absolute h-screen overflow-hidden z-[-1]">
      <img className="w-full sm:h-full sm:w-auto" src={loginBackFile ? URL.createObjectURL(loginBackFile) : ``}/>
    </div>
    
    <div className={`h-screen flex justify-center items-center bg-secondaryTransparent10 z-10 ${styleProxyMap[props.backgroundColor]}`}>
      <div className="absolute top-0 flex items-center justify-center bg-gradient-to-b from-white to-transparent p-4 rounded-b-lg">
        <img className="h-48" src={logoFile ? URL.createObjectURL(logoFile) : ``} />
      </div>
      <div className="flex flex-col gap-2 bg-secondaryTransparent50 px-16 py-4">
          <label className="text-sm">User Id</label>
            <input
              className="m-0 rounded-sm"
              type="text"
              name="email"
              onChange={(event) => handleChange(event)}
            ></input>
            <br />
            <label className="text-sm">Password</label>
            <input
            className="m-0 rounded-sm"
              type="password"
              name="password"
              onChange={(event) => handleChange(event)}
            ></input>
            <br />
            <BtnNormal spClass="mx-auto" color="primaryPale" label="Sign In" type="action" clickHandler={() => signIn(email, password)}/>

      </div>
      <div className="absolute flex-center bottom-10 w-screen gap-8">
        {/* <img src={dndNcodeLogo} width={150}/> */}
            {/* <BtnNormal spClass="margin-auto" color="primaryPale" label="Request Sign-Up" type="action" clickHandler={() => btnClickFn("signup")}/> */}
       </div>
       {showPromptOp && <ModalBoxMessagePromptWithOperation passMap={promptMap} promptClose={()=>setShowPromptOp(false)}/>}
       {showPromptOp2 && <ModalBoxMessagePromptWithMultipleOps passMap={promptMap} promptClose={()=>setShowPromptOp2(false)}/>}
       {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
       {showSpinner && <LoadSpinner />}
    </div>
    
    </div>

  );
}
