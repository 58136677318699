import React, { useEffect, useState } from "react";
import { getMonthYearShort } from "../Utils";
import StatSection from "./StatSection";

export default function StatCont(props) {
  console.log(props)

  function getFinalTitle(title){
    if(title=="Earnings"){
      return `${title} (${getMonthYearShort(new Date(), " ")})`
    }
    return title
  }


  // function getStatDataMap(dataMap,schemeMap,storeSettingsData,userData){


  //   const rMap={}
  
  //   schemeMap.statTitles.forEach((title,i)=>{
  //     rMap[title]={}
  
  //     switch(title){
  //       case "Teachers":{
  //         rMap[title]["engaged"]=0
  //         rMap[title]["idle"]=0
  //         rMap[title]["absent"]=0
  //         rMap[title]["total"]=0
  
  //         if(Object.keys(dataMap.storeBranchEmployeeMap).length>0){
  //         Object.keys(dataMap.storeBranchEmployeeMap).forEach((emplUid)=>{
  //           const uMap = dataMap.storeBranchEmployeeMap[emplUid];
  //           if(!uMap.isDisabled){
  //             rMap[title]["total"]+=1
  //             if(uMap.isAbsent){
  //               rMap[title]["absent"]+=1
  //             }else{
  //               if(uMap.isEngaged){
  //                 rMap[title]["engaged"]+=1
  //               }else{
  //                 rMap[title]["idle"]+=1
  //               }
  //             }
  //           }
  //         })
  //       }
  //       }
     
  //       break;
  //       case "Time":{
  
  //         if(Object.keys(dataMap.timeMap).length>0){
  //           console.log(dataMap.timeMap)
  //           rMap[title]["estd wait time"] = dataMap.timeMap.ovrWaitTimeCond ? (dataMap.timeMap.ovrWaitTime/60).toFixed(2) : dataMap.timeMap.estdWaitTime.toFixed(2);
  //         }else{
  //           rMap[title]["estd wait time"]="NA"
  //         }
  //       }
  //       break;
  //       case "Performance":{
  
  //         const labels = schemeMap.statNames[i]
  //         const keys = schemeMap.statDbKeys[i]
  
  //         labels.forEach((label,j)=>{
  //           rMap[title][label] = dataMap["studentExamMarksInfoMap"][keys[j]]
  //         })
  //       }
  //       break;
  //       case "Earnings":{
  
  //         if(userData.role=="Store Branch"){
  
  //               const curMonthYear=getMonthYear(new Date(Date.now()))
  //               if(Object.keys(dataMap.earningMap).length>0){
            
  //                   if(Object.keys(dataMap["earningMap"]).includes(curMonthYear)){
  
  //                     console.log(dataMap["earningMap"])
  
  //                     const currencySymbol = getCurrencySymbol(storeSettingsData.storeBranchCurrency)
  
  //                     const earningPpdCard = Object.keys(dataMap["earningMap"][curMonthYear]).includes("prepaidCard")?dataMap["earningMap"][curMonthYear].prepaidCard:0;
  //                     const earningCashCard = Object.keys(dataMap["earningMap"][curMonthYear]).includes("cashCard")?dataMap["earningMap"][curMonthYear].cashCard:0;
  //                     const earningTotal = earningPpdCard+earningCashCard;
  
  //                     rMap[title]["prepaid cards"] = `${currencySymbol}${earningPpdCard.toFixed(2)}`
  //                     rMap[title]["cash/card"] = `${currencySymbol}${earningCashCard.toFixed(2)}`
  //                     rMap[title]["total"]=`${currencySymbol}${earningTotal.toFixed(2)}`
  
  
  //                   }
  //             }else{
  //               rMap[title]["prepaid cards"] = 0;
  //               rMap[title]["cash/card"] = 0;
  //               rMap[title]["total"]=0;
  
  //             }
           
  
  //        }else if(userData.role=="Super Admin"){
  
  //         const currencySymbol = getCurrencySymbol("CAD")
  
  //         const paidInvoiceMapColl = dataMap.paidInvoiceColl;
  //         const unpaidInvoiceMapColl = dataMap.unpaidInvoiceColl;
  
  //         rMap[title]["received"] = 0;
  
  //         console.log(paidInvoiceMapColl.length)
  
  //         if(paidInvoiceMapColl.length==0){
  
  //         }else{
  //           paidInvoiceMapColl.forEach((map)=>{
  //             console.log(map)
  //             const otherMonthYearCond = map.invoiceMonthYears.length!=1;
  
  //             const paymentDateIndex = 9; // The payment date is at index 9 of the Strip Seq
  //             const invAmountIndex = 6; // The invoice amount is at index 6 of the Strip Seq
  
  //             if(otherMonthYearCond){
  //               map.stringSeqDatas.forEach((seq)=>{
  //               const paymentDate = seq.split("^")[paymentDateIndex]
  //               const paymentMonthYear = getMonthYear(new Date(parseInt(paymentDate)*1000))
  //               const currentMonthYear = getMonthYear(new Date());
  
  //               const invoiceAmount = parseFloat(seq.split("^")[invAmountIndex])
  //               if(paymentMonthYear==currentMonthYear){ //Amount is shown received if the bill is of this month
  //                 rMap[title]["received"] += invoiceAmount;
  //                }
  //              })
  //             }else{
  //               map.stringSeqDatas.forEach((seq)=>{
  //                 const invoiceAmount = parseFloat(seq.split("^")[invAmountIndex])
  //                 rMap[title]["received"] += invoiceAmount;
  //               })
  //             }
  
  //         })
  
  //         }
  
  //         rMap[title]["billed"]=0
  //         rMap[title]["overdue"]=0
  
  //         unpaidInvoiceMapColl.forEach((map)=>{
  
  //           const dueDateIndex = 9; // The due date milli value is at index 9 of the Strip Seq
  //           const billedDateIndex = 2; // The invoice amount is at index 6 of the Strip Seq
  //           const invoiceAmountIndex = 6; // The invoice amount is at index 6 of the Strip Seq
  
  //           map.stringSeqDatas.forEach((seq)=>{
  
  //             const billedDate = seq.split("^")[billedDateIndex];
  //             const dueDate = parseInt(seq.split("^")[dueDateIndex])*1000
  //             const todaysDate = new Date().getTime();
  
  //             const invoiceAmount = parseFloat(seq.split("^")[invoiceAmountIndex])
  
  //             console.log("todaysDate="+todaysDate,"dueDate="+dueDate)
  
  //             if(todaysDate>dueDate){
  //               rMap[title]["overdue"] += invoiceAmount
  //             }else{
  //               rMap[title]["billed"] += invoiceAmount
  //             }
  
  
  //           })
  
  
  //         })
          
  //         rMap[title]["total"]= `${currencySymbol}${(rMap[title]["received"]+rMap[title]["billed"]+rMap[title]["overdue"]).toFixed(2)}`
  //         rMap[title]["received"] = `${currencySymbol}${rMap[title]["received"].toFixed(2)}`;
  //         rMap[title]["billed"] = `${currencySymbol}${rMap[title]["billed"].toFixed(2)}`;
  //         rMap[title]["overdue"] = `${currencySymbol}${rMap[title]["overdue"].toFixed(2)}`;
  //       }
  //       }
  //       break;
  //       case "Accounts":{
  
  //         console.log(dataMap)
  //         rMap[title]["brands"]=Object.keys(dataMap["allStoreBrandMap"]).length;
  //         rMap[title]["branches"]=getNestedChildrenCount(dataMap["allStoreBranchMap"]);
  //         rMap[title]["employees"]=getNestedChildrenCount(dataMap["allEmployeeMap"]);
  //         rMap[title]["customers"]=0;
  //         rMap[title]["total"]=rMap[title]["brands"]+rMap[title]["branches"]+rMap[title]["employees"]+rMap[title]["customers"]
  
  //         function getNestedChildrenCount(map){
  //           let count=0
  //           console.log(Object.keys(map))
  //           Object.keys(map).forEach((key)=>{
  //             // console.log(key,Object.keys(key))
  //               console.log(Object.keys(map[key]).length)
  //               count+=Object.keys(map[key]).length
  //           })
  
  //           return count;
  //         }
      
  //       }
  //       break;
  //       case "Branches":{
  
  //         console.log(dataMap)
  //         rMap[title]["total"]=dataMap["allStoreBranchMap"][userData.userUid] == null?0:Object.keys(dataMap["allStoreBranchMap"][userData.userUid]).length;
  
  //         console.log(rMap[title]["total"])
  //         // rMap[title]["employees"]=getNestedChildrenCount(dataMap["allEmployeeMap"]);
  //         // rMap[title]["customers"]=0;
  //         // rMap[title]["total"]=rMap[title]["brands"]+rMap[title]["branches"]+rMap[title]["employees"]+rMap[title]["customers"]
      
  //       }
  //     }
  
  //   })
  
  //   console.log(rMap)
  
  //   return rMap
  
  // }


  const [statSectionUnitValuesColl,setStatSectionUnitValuesColl] = useState([])

  useEffect(()=>{

    (async () => await functions("initializeData",{}))()

  },[props.dataMapObj])


  async function functions(fnName,data){
    switch(fnName){
      case "initializeData":{

        const userRole = props.dataMapObj.userDataMap.role;

        console.log(props)

        console.log(userRole)

            const statSectionUnitValuesColl = []

            props.schemeMap.statSectionTitles.forEach((statSectionTitle,i)=>{

              const statSectionUnitLabels = props.schemeMap.statSectionUnitLabelsColl[i];
              const statUnitValues = []

              statSectionUnitLabels.forEach((statUnitLabel,j)=>{
                const statComputeOpType = props.schemeMap.statSectionComputeOpTypesColl[i][j]
                const initStatComputeKeyScheme = props.schemeMap.statSectionComputeKeySchemesColl[i][j]
                const statComputeKey = props.schemeMap.statSectionComputeKeysColl[i][j]
                const statDataType = props.schemeMap.statDataTypesColl[i][j]

                const statComputeKeySchemes = Array.isArray(initStatComputeKeyScheme) ?  [...initStatComputeKeyScheme] : [initStatComputeKeyScheme]
                
                const valueComps = statComputeKeySchemes.map((keyScheme,k)=>{
                  switch(keyScheme){
                    case "mapObjLength":{
                      const mapObjKey = Array.isArray(statComputeKey) ? statComputeKey[k] : statComputeKey
                      return Object.keys(props.dataMapObj.dbMap[mapObjKey]).length
                    }
                    case "mapObjProperty":{
                      const mapObjKey = Array.isArray(statComputeKey) ? statComputeKey[k].split(".")[0] : statComputeKey.split(".")[0]
                      const mapProperty = Array.isArray(statComputeKey) ? statComputeKey[k].split(".")[1] : statComputeKey.split(".")[1]
                      return props.dataMapObj.dbMap[mapObjKey][mapProperty]
                    }
                  }
                });



                const statUnitValue = (function(){
                  switch(statComputeOpType){
                    case "addition":{
                      return valueComps.reduce((ac,cv)=>ac+cv,0)
                    }
                    case "fractionStrExpression":{
                      return `${valueComps[0]} / ${valueComps[1]}`

                    }
                    default:return valueComps[0]
                  }
                })()

                const formattedStatUnitValue = statDataType=="decimal" ? parseFloat(statUnitValue).toFixed(2) : statUnitValue

                statUnitValues.push(formattedStatUnitValue)

              })


              statSectionUnitValuesColl.push(statUnitValues)

              setStatSectionUnitValuesColl([...statSectionUnitValuesColl])


            })


        
        

      }
    }
  }

  
  // function getStatSectionValues(schemeMap,sDataMap,sectionIndex){

  //   console.log(schemeMap,sDataMap,sectionIndex)

  //   const dataMap = sDataMap.dataMap

  //   const sectionValues = []
  //   const statValDataTypes = schemeMap.statValDataTypes[sectionIndex]
  //   const statDbKeys = schemeMap.statDbKeys[sectionIndex]

  //   schemeMap.statLabels[sectionIndex].forEach((statLabel,j)=>{

  //     const value = "keyType" in statValDataTypes[j] ? getComplexStatValue(dataMap,statValDataTypes[j],statDbKeys[j]) : getSimpleStatValue(dataMap,statValDataTypes[j],statDbKeys[j]);

  //     sectionValues.push(value)


  //   })

  //   return sectionValues;

    

  //   function getComplexStatValue(dataMap,valDataType,dbKeys){
  //     console.log(dataMap,valDataType,dbKeys)
  //     switch(valDataType.valType){
  //       case "percentExpression": {
  //         const numerator = dbKeys.split("^")[0]
  //         const denominator = dbKeys.split("^")[1]
  //         console.log(numerator,denominator)
  //         console.log(`${dataMap[numerator]}/${dataMap[denominator]}`)
  //         return `${dataMap[numerator]}/${dataMap[denominator]}`
  //       }
  //     }
  //   }

    
  //   function getSimpleStatValue(dataMap,valDataType,dbKey){
  //       return dataMap[dbKey]
  //   }

  // }




  return (
    <React.Fragment>
   <div className="h-full flex flex-col">
    {statSectionUnitValuesColl.length > 0 && props.schemeMap.statSectionTitles.map((statSectionTitle,i)=>{
      return(      
      <StatSection
        statSectionTitle={getFinalTitle(statSectionTitle)}
        statSectionUnitLabels={props.schemeMap.statSectionUnitLabelsColl[i]}
        statSectionUnitValues={statSectionUnitValuesColl[i]}
        sectionIndex={i}
        schemeMap={props.schemeMap}
        />)
    })}
    </div>
    </React.Fragment>
  )
}
