import { equalTo, orderByChild } from "firebase/database";
import { collection, collectionGroup, getDocs, limit, orderBy, Timestamp, where } from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore/lite";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts.js";
import { child, database, db, get, query, rbaseQuery, rbaseRef, update } from "../Utils";
import { inpOptionsKeyAliasMap, inpOptionsMap, keyAliasMap, shortCodeMap } from "./DataList";
import { convertRbaseTimestampObjToFbTimestamp, getDateStrFromJSDate, getDateTimeFromMySqlTimestampString, getMonthDateCodeFromJsDate, getOnlyDateFromMySqlTimestampString, getPercentage, getWeekNoFromJsDate, ifValueIsDefined, naturalSortMapsByKey } from "./genFunctions";
import { generateInstituteSectionSheet, generateStudentSheet } from "./PdfDataGenerator";
import { getEndDateOfThisMonth, getStartDateOfLastMonth } from "./TimeCustom";
import { attendanceDocToRecordSchemeMap, examMarksDocToRecordSchemeMap, pdfGenerateSchemeMap, statSchemeMapInstitute, statSchemeMapPrincipal, statSchemeMapStudent, statSchemeMapSuperAdmin, studentAttendanceDocToRecordSchemeMap, studentExamMarksDocToRecordSchemeMap } from "./viewProps";


export function getShortCode(key,value){

    let formattedKey = key

    if(key in keyAliasMap){
        formattedKey = keyAliasMap[key]
    }

    return shortCodeMap[formattedKey][value]

}


export function getInpBackgroundColor(accessMode,regResult){

  console.log(regResult)

  if(!regResult){
    return `bg-redLight`
  }else if(accessMode=="resubmit"){
    return `bg-redPale`
  }else{
    return ''
  }
}


export async function fetchAndGenerateMarksSheet(dataMap){

  console.log(dataMap)

  const dbMapObj = dataMap.selectionNodeDbMapObj

  const addlDataRefMap = {
    examMaxMarks:dbMapObj.exam.examMaxMarks
  }

  const viewRecordMaps = getViewRecordMapsFromDocMaps(examMarksDocToRecordSchemeMap,dataMap.sheetRowDataMaps,addlDataRefMap);
  //const examMarksMapObj = await getStudentExamMarksDataMapObj(dataMap)

  console.log(viewRecordMaps)

  const genDataMap = {
    ...dbMapObj.exam,
    examDate:getDateStrFromJSDate(convertRbaseTimestampObjToFbTimestamp(dbMapObj.exam.examDate).toDate()),
    instituteName:dbMapObj.institute.instituteName
  }

  const pdfDataMap = {
    listRecordMaps:viewRecordMaps,
    generalDataMap:genDataMap
  }

  generatePdfStudent({},pdfDataMap,"examMarks",{},"download")
}

export async function fetchAndGenerateAttendanceSheet(dataMap){

  console.log(dataMap)

  const dbMapObj = dataMap.selectionNodeDbMapObj

  const addlDataRefMap = {
  }

  const viewRecordMaps = getViewRecordMapsFromDocMaps(attendanceDocToRecordSchemeMap,dataMap.sheetRowDataMaps,addlDataRefMap);


  console.log(viewRecordMaps)

  const genDataMap = {
    className:dbMapObj.class.name,
    sectionName:dbMapObj.section.name,
    subjectName:dbMapObj.subject.name,
    instituteName:dbMapObj.institute.name,
    attendanceDate:getDateStrFromJSDate(dbMapObj.attendance.attendanceDate.toDate()),
    totalClassCount:dbMapObj.attendance.totalClassCount
  }

  const pdfDataMap = {
    listRecordMaps:viewRecordMaps,
    generalDataMap:genDataMap
  }

  generatePdfStudent({},pdfDataMap,"attendance",{},"download")
}

export async function fetchAndGenerateStudentMarksSheet(dataMap){

  console.log(dataMap)

  const dbDocSnapshots = await getStudentExamMarkDocSnapshots(dataMap);

  dataMap["examMarksDateRange"] = "01-08-2024 to 05-09-2024"

  console.log(dbDocSnapshots)

  const viewRecordMaps = getViewRecordMapsFromDbDocSnapshots(studentExamMarksDocToRecordSchemeMap,dbDocSnapshots,dataMap);
  //const examMarksMapObj = await getStudentExamMarksDataMapObj(dataMap)

  console.log(viewRecordMaps)

  const pdfDataMap = {
    listRecordMaps:viewRecordMaps,
    generalDataMap:dataMap
  }

  generatePdfStudent({},pdfDataMap,"studentExamMarks",{},"download")
}

export async function getTempStudentMarksSheetDataMap(dataMap){
  console.log(dataMap)

  const dbDocSnapshots = await getStudentExamMarkDocSnapshots(dataMap);

  dataMap["examMarksDateRange"] = "01-08-2024 to 05-09-2024"

  console.log(dbDocSnapshots)

  const viewRecordMaps = getViewRecordMapsFromDbDocSnapshots(studentExamMarksDocToRecordSchemeMap,dbDocSnapshots,dataMap);
  //const examMarksMapObj = await getStudentExamMarksDataMapObj(dataMap)

  console.log(viewRecordMaps)

  const pdfDataMap = {
    listRecordMaps:viewRecordMaps,
    generalDataMap:dataMap
  }

  return pdfDataMap
}


export async function fetchAndGenerateStudentAttendanceSheet(dataMap){

  console.log(dataMap)

  const dbDocSnapshots = await getStudentAttendanceDocSnapshots(dataMap);

  console.log(dbDocSnapshots)

  const viewRecordMaps = getViewRecordMapsFromDbDocSnapshots(studentAttendanceDocToRecordSchemeMap,dbDocSnapshots,dataMap);
  //const examMarksMapObj = await getStudentExamMarksDataMapObj(dataMap)

  console.log(viewRecordMaps)

  const pdfDataMap = {
    listRecordMaps:viewRecordMaps,
    generalDataMap:dataMap
  }

  generatePdfStudent({},pdfDataMap,"studentAttendance",{},"download")
}


export function getInpOptions(inpKey){
  const optionKey = inpKey in inpOptionsKeyAliasMap ? inpOptionsKeyAliasMap[inpKey] : inpKey;
  return inpOptionsMap[optionKey]
}



export function getAutoGeneratedValue(inpSchemeMap,inpValMap,inpKey){

    const autoGenSchemeMap = inpSchemeMap.autoGenSchemeMap[inpKey];
    const seperator = autoGenSchemeMap.seperator;

    let genValue =""

    const encodedValues = autoGenSchemeMap.keys.map((key,i)=>{

        const encoding = autoGenSchemeMap.encoding[i];
        const keyValue = inpValMap[key]
        

        switch(encoding){
            case "shortCode" : return getShortCode(key,keyValue)
            case "monthDate" : return getMonthDateCodeFromJsDate(new Date(keyValue))
            case "monthDateFromTimestamp" : return getMonthDateCodeFromJsDate(keyValue ? keyValue.toDate() : null)
            case "weekNo" : return getWeekNoFromJsDate(new Date(keyValue))
            default:return keyValue
        }
    })

    console.log(autoGenSchemeMap)

    const sanitizedEncodedValues = encodedValues.map((value)=> ifValueIsDefined(value) ? value : "X" )

    return sanitizedEncodedValues.join(seperator)

}


export function getRealisedRbasePath(rbasePathStr,valMap){

  const pathComps = [];

  for(const pathComp of rbasePathStr.split("/")){
    if(pathComp[0]=="$"){
      const keyComp = pathComp.slice(1);
      const actualPath = valMap[keyComp];
      actualPath ? pathComps.push(actualPath) : pathComps.push(null)
    }else{
      pathComps.push(pathComp)
    }
  }

  // const path = rbasePathStr.split("/").map((pathComp)=>{
  //   if(pathComp[0]=="$"){
  //     const keyComp = pathComp.slice(1);
  //     const actualPath = valMap[keyComp]
  //     return actualPath
  //   }else{
  //     return pathComp
  //   }
  // }).join("/")
  // console.log(rbasePathStr,valMap,path)


  return pathComps.includes(null) ? null : pathComps.join("/")
}



export async function getRoleDocPath(userRole,userData){
          
  switch(userRole){
      case "Institute": return (`institutes/${userData.userUid}`)
      case "Principal": {
        const queryA = query(collectionGroup(db,`staff`),where("staffUid","==",userData.userUid),orderBy("instituteAcademicYear","desc"),limit(1));

        const querySnapshot = await getDocs(queryA);
        const roleDoc = querySnapshot.docs[0];
        return roleDoc.ref.path
      }
      case "Student": {
        const queryA = query(collectionGroup(db,`students`),where("studentUid","==",userData.userUid),orderBy("instituteAcademicYear","desc"),limit(1));

        const querySnapshot = await getDocs(queryA);
        const roleDoc = querySnapshot.docs[0];


        return roleDoc.ref.path
      }
      case "Super Admin":{
        return `admin/general`
      }
      case "Master":{
        return `admin/general`
      }
    }

  }

export async function getDbInpOptions(dbSelectionSchemeMap,valMap){

  const realisedRbasePath = getRealisedRbasePath(dbSelectionSchemeMap.rbasePath,valMap);

    if(realisedRbasePath){
      console.log(realisedRbasePath)

      const rBaseCall = await get(child(rbaseRef(
        database
      ),realisedRbasePath));
           
      const  mapObj = rBaseCall.val();


   
        return mapObj ? convertRbaseMapObjToArray(mapObj,dbSelectionSchemeMap.rbaseNodeKeyName) : []
      

    }else{
      return []
    }

}


export function getCommonMapsByKeyBetweenMapsCollection(sMapsCollection,commonKey){
  console.log(sMapsCollection)

  const  mapsCollection = JSON.parse(JSON.stringify(sMapsCollection))

  if(mapsCollection.length <= 1){
    return mapsCollection.length == 1 ? mapsCollection[0] : [];
  }else{


  const maps1 = mapsCollection[0]
  const maps2 = mapsCollection[1]

  const commonMaps = []
  
  for(const map1 of maps1){

    for(const map2 of maps2){
        if (map1[commonKey] == map2[commonKey]){
          commonMaps.push(map1);
        }
    }
    
  }

  mapsCollection.splice(0,2,commonMaps);

   return getCommonMapsByKeyBetweenMapsCollection(mapsCollection,commonKey)
  }

}

export async function getStudentAttendanceDocSnapshots(dataMap){

  const {instituteUid} = dataMap;
  const {instituteAcademicYear} = dataMap
  const {className} = dataMap
  const {sectionName} = dataMap
  const {studentUid} = dataMap



  const queryStatement = query(
  collection(db,`institutes/${instituteUid}/attendance`),
  where(`instituteAcademicYear`,"==",instituteAcademicYear),
  where("className","==",className),
  where("sectionName","==",sectionName),
);

  const querySnapshot = await getDocs(queryStatement);


  return querySnapshot.docs;

}

export async function getStudentExamMarkDocSnapshots(dataMap){

  const {instituteUid} = dataMap;
  const {instituteAcademicYear} = dataMap
  const {className} = dataMap
  const {sectionName} = dataMap
  const {studentUid} = dataMap

  // console.log(instituteUid,instituteAcademicYear,className,sectionName)
 const startTimestampOfLastMonth = Timestamp.fromDate(getStartDateOfLastMonth())
 const endTimestampOfThisMonth =  Timestamp.fromDate(getEndDateOfThisMonth())


 console.log(startTimestampOfLastMonth,endTimestampOfThisMonth)

  const queryStatement = query(
  collection(db,`institutes/${instituteUid}/examMarks`),
  where(`instituteAcademicYear`,"==",instituteAcademicYear),
  where("className","==",className),
  where("sectionName","==",sectionName),
  where("examDate",">=",startTimestampOfLastMonth),
  where("examDate","<=",endTimestampOfThisMonth),
);

  const querySnapshot = await getDocs(queryStatement);


  return querySnapshot.docs;

}

export function getViewRecordMapsFromDbDocSnapshots(dbToRecordSchemeMap,dbDocSnapshots,addlDataRefMap){

  console.log(dbToRecordSchemeMap,dbDocSnapshots,addlDataRefMap)

  const viewRecordMaps = [];
  const recordKeys = dbToRecordSchemeMap.recordKeys

  dbDocSnapshots.forEach((docSnapshot)=>{

    const viewRecordMap = {}
    const docMap = docSnapshot.data();

    const dataMap = {...docMap,...addlDataRefMap}

    recordKeys.forEach((recordKey,j)=>{

      const recordDataType = dbToRecordSchemeMap.recordDataTypes[j];
      const recordComputeOpType = dbToRecordSchemeMap.recordComputeOpTypes[j];

      const initRecordComputeKeyScheme = dbToRecordSchemeMap.recordComputeKeySchemes[j];
      const recordComputeDbKey = dbToRecordSchemeMap.recordComputeDbKeys[j];
  
      const recordComputeKeySchemes = Array.isArray(initRecordComputeKeyScheme) ?  [...initRecordComputeKeyScheme] : [initRecordComputeKeyScheme]
  
      const valueComps = recordComputeKeySchemes.map((keyScheme,k)=>{
        switch(keyScheme){
          case "mapObjLength":{
            const mapObjKey = Array.isArray(recordComputeDbKey) ? recordComputeDbKey[k] : recordComputeDbKey
           // return Object.keys(props.dataMapObj.dbMap[mapObjKey]).length
          }
          case "mapObjProperty":{
            const mapObjKey = Array.isArray(recordComputeDbKey) ? recordComputeDbKey[k].split(".")[0] : recordComputeDbKey.split(".")[0]
            const mapProperty = Array.isArray(recordComputeDbKey) ? recordComputeDbKey[k].split(".")[1] : recordComputeDbKey.split(".")[1]
           // return props.dataMapObj.dbMap[mapObjKey][mapProperty]
          }
          case "baseMapChildMapProperty":{ //base map is stripValueMap here
            const childMapKeyOnBaseMap = Array.isArray(recordComputeDbKey) ? getRealisedKeyValue(dataMap,recordComputeDbKey[k].split(".")[0]) : getRealisedKeyValue(dataMap,recordComputeDbKey.split(".")[0])
            const childMapProperty = Array.isArray(recordComputeDbKey) ? getRealisedKeyValue(dataMap,recordComputeDbKey[k].split(".")[1]) : getRealisedKeyValue(dataMap,recordComputeDbKey.split(".")[1])
            return dataMap[childMapKeyOnBaseMap][childMapProperty]
  
          }
          default:{
            const mapProperty = Array.isArray(recordComputeDbKey) ? recordComputeDbKey[k].split(".")[0] : recordComputeDbKey.split(".")[0]
            return dataMap[mapProperty]
          }
        }
      });
  
      const recordValue = (function(){
        switch(recordComputeOpType){
          case "addition":{
            return valueComps.reduce((ac,cv)=>ac+cv,0)
          }
          case "fractionStrExpression":{
  
            if(valueComps[0] && valueComps[1]){
                return `${valueComps[0]} / ${valueComps[1]}`
            }else{
              return `NA`
            }
          }
          case "percentage":{
  
            if(valueComps[0] && valueComps[1]){
                return getPercentage(valueComps[0],valueComps[1],2)
            }else{
              return ``
            }
          }
          default:return valueComps[0]
        }
      })()

      viewRecordMap[recordKey] = getFormattedRecordValue(recordDataType,recordValue);
      
    })

    viewRecordMaps.push(viewRecordMap)

  })

  console.log(viewRecordMaps)

  return viewRecordMaps
}

export function getViewRecordMapsFromDocMaps(docMapToRecordSchemeMap,docMaps,addlDataRefMap){

  console.log(docMapToRecordSchemeMap,docMaps,addlDataRefMap)

  const viewRecordMaps = [];
  const recordKeys = docMapToRecordSchemeMap.recordKeys

  docMaps.forEach((tDocMap)=>{

    const docMapType = docMapToRecordSchemeMap.docMapType

    const viewRecordMap = {}
    const docMap = docMapType=="dbDocSnapshot" ? tDocMap.data() : tDocMap;

    const dataMap = {...docMap,...addlDataRefMap}

    recordKeys.forEach((recordKey,j)=>{

      const recordDataType = docMapToRecordSchemeMap.recordDataTypes[j];
      const recordComputeOpType = docMapToRecordSchemeMap.recordComputeOpTypes[j];

      const initRecordComputeKeyScheme = docMapToRecordSchemeMap.recordComputeKeySchemes[j];
      const recordComputeDbKey = docMapToRecordSchemeMap.recordComputeDbKeys[j];
  
      const recordComputeKeySchemes = Array.isArray(initRecordComputeKeyScheme) ?  [...initRecordComputeKeyScheme] : [initRecordComputeKeyScheme]
  
      const valueComps = recordComputeKeySchemes.map((keyScheme,k)=>{
        switch(keyScheme){
          case "mapObjLength":{
            const mapObjKey = Array.isArray(recordComputeDbKey) ? recordComputeDbKey[k] : recordComputeDbKey
           // return Object.keys(props.dataMapObj.dbMap[mapObjKey]).length
          }
          case "mapObjProperty":{
            const mapObjKey = Array.isArray(recordComputeDbKey) ? recordComputeDbKey[k].split(".")[0] : recordComputeDbKey.split(".")[0]
            const mapProperty = Array.isArray(recordComputeDbKey) ? recordComputeDbKey[k].split(".")[1] : recordComputeDbKey.split(".")[1]
           // return props.dataMapObj.dbMap[mapObjKey][mapProperty]
          }
          case "baseMapChildMapProperty":{ //base map is stripValueMap here
            const childMapKeyOnBaseMap = Array.isArray(recordComputeDbKey) ? getRealisedKeyValue(dataMap,recordComputeDbKey[k].split(".")[0]) : getRealisedKeyValue(dataMap,recordComputeDbKey.split(".")[0])
            const childMapProperty = Array.isArray(recordComputeDbKey) ? getRealisedKeyValue(dataMap,recordComputeDbKey[k].split(".")[1]) : getRealisedKeyValue(dataMap,recordComputeDbKey.split(".")[1])
            return dataMap[childMapKeyOnBaseMap][childMapProperty]
  
          }
          default:{
            const mapProperty = Array.isArray(recordComputeDbKey) ? recordComputeDbKey[k].split(".")[0] : recordComputeDbKey.split(".")[0]
            return dataMap[mapProperty]
          }
        }
      });
  
      const recordValue = (function(){
        switch(recordComputeOpType){
          case "addition":{
            return valueComps.reduce((ac,cv)=>ac+cv,0)
          }
          case "fractionStrExpression":{
  
            if(valueComps[0] && valueComps[1]){
                return `${valueComps[0]} / ${valueComps[1]}`
            }else{
              return `NA`
            }
          }
          case "percentage":{
  
            if(valueComps[0] && valueComps[1]){
                return getPercentage(valueComps[0],valueComps[1],2)
            }else{
              return ``
            }
          }
          default:return valueComps[0]
        }
      })()

      viewRecordMap[recordKey] = getFormattedRecordValue(recordDataType,recordValue);
      
    })

    viewRecordMaps.push(viewRecordMap)

  })

  console.log(viewRecordMaps)

  return viewRecordMaps
}

export function getFormattedRecordValue(dataType,initCellValue){
  switch(dataType){
    //case "status":  return initCellValue ? statusLabels[statusKeys.indexOf(initCellValue)] : "NA";
    case "dateTime": return initCellValue ? getDateTimeFromMySqlTimestampString(initCellValue) : "NA"
    case "date": return initCellValue ? getOnlyDateFromMySqlTimestampString(initCellValue) : "NA"
    case "date-timestamp": return initCellValue ? getDateStrFromJSDate(initCellValue.toDate()) : "NA"
    case "arrayData" : return initCellValue.join(", ")
    case "boolean" : return initCellValue ? "Yes" : "No";
    case "list": return Array.isArray(initCellValue) ? initCellValue.join(" , ") : initCellValue;
    case "percentage": return initCellValue ? `${initCellValue}%` : "NA";
    default:return initCellValue
  }
}

export function getRealisedKeyValue(dataMap,key){
  return key[0]=="$" ? dataMap[key.slice(1)] : key;
 }

export async function getStudentExamMarksDataMapObj(dataMap){

  const {instituteUid} = dataMap;
  const {instituteAcademicYear} = dataMap
  const {className} = dataMap
  const {sectionName} = dataMap
  const {studentUid} = dataMap

  // console.log(instituteUid,instituteAcademicYear,className,sectionName)
 const startTimestampOfLastMonth = Timestamp.fromDate(getStartDateOfLastMonth())
 const endTimestampOfThisMonth =  Timestamp.fromDate(getEndDateOfThisMonth())


 console.log(startTimestampOfLastMonth,endTimestampOfThisMonth)

  const queryStatement = query(
  collection(db,`institutes/${instituteUid}/examMarks`),
  where(`instituteAcademicYear`,"==",instituteAcademicYear),
  where("className","==",className),
  where("sectionName","==",sectionName),
  where("examDate",">=",startTimestampOfLastMonth),
  where("examDate","<=",endTimestampOfThisMonth),
);

  const querySnapshot = await getDocs(queryStatement);


  const attendedExamMaps = []; // to hold present student exam marks map
  const absentExamMaps = []; // To hold completed orders

  const marksInfoMap = {
    examConductedCount:querySnapshot.size,
    examAttendedCount:0,
    avgMarks:0,
    avgMarksData:[],
    totalMarks:0
  }

  querySnapshot.forEach((doc) => {

    const docData = doc.data();

    if(docData.examAttendedStudentUids.includes(studentUid)){
      marksInfoMap.examAttendedCount += 1;
      attendedExamMaps.push({...docData,examPresentCond:true})
      marksInfoMap.totalMarks += docData.studentExamMarksMap[studentUid];
    }else{
      absentExamMaps.push({...docData,examPresentCond:false})
    }

  });

  const avgMarksData = [marksInfoMap.totalMarks,marksInfoMap.examAttendedCount]

  marksInfoMap.avgMarksData = [...avgMarksData]
  marksInfoMap.avgMarks = marksInfoMap.totalMarks/marksInfoMap.examAttendedCount

  const allExamMaps = attendedExamMaps.concat(absentExamMaps)

  const examMarksMapObj = {
    allExamMaps:allExamMaps,
    attendedExamMaps:attendedExamMaps,
    absentExamMaps:absentExamMaps,
    examInfoMap:marksInfoMap
  }  
  
  return examMarksMapObj;

}


export async function getDbMultiDependencyInpOptions(dbMultiDependencySelectionSchemeMap,valMap){
  console.log(dbMultiDependencySelectionSchemeMap,valMap)

  const dbMapObjs = []

  const multiDependencyKey = dbMultiDependencySelectionSchemeMap.multiDependencyKey;
  const multiDependencyKeyBaseName = dbMultiDependencySelectionSchemeMap.multiDependencyKeyBaseName;
  const key = dbMultiDependencySelectionSchemeMap.key;
  const rbasePath = dbMultiDependencySelectionSchemeMap.rbasePath
  const rbaseNodeKeyName =  dbMultiDependencySelectionSchemeMap.rbaseNodeKeyName

  for (const value of valMap[multiDependencyKey]){
    valMap[multiDependencyKeyBaseName] = value //example valMap["sectionName"] = "A"
    const realisedRbasePath = getRealisedRbasePath(rbasePath,valMap);

    if(realisedRbasePath){
      console.log(realisedRbasePath)

      const rBaseCall = await get(child(rbaseRef(
        database
      ),realisedRbasePath));
           
      const  mapObj = rBaseCall.val();

      
      if(mapObj){
        dbMapObjs.push(convertRbaseMapObjToArray(mapObj,rbaseNodeKeyName))
      }
    }
  }


  console.log("3")

  const mapsCollection = JSON.parse(JSON.stringify(dbMapObjs))

  //console.log(dbMapObjs,getCommonMapsByKeyBetweenMapsCollection(mapsCollection,"subjectName"))

  return getCommonMapsByKeyBetweenMapsCollection(mapsCollection,"subjectName")
}





export async function getRbaseMapValue(rbaseSchemeMap,rbaseNodeKeyValue){

  console.log(rbaseSchemeMap)
  const rBaseCall = await get(child(rbaseRef(
    database
  ),`${rbaseSchemeMap.rbasePath}/${rbaseSchemeMap.rbaseNodeKeyName}/${rbaseSchemeMap.rbaseValueKeyName}`));
  const  val = rBaseCall.val()
  console.log(val)
  return val;
}

export async function getRbaseValueByQuery(rbasePath,type,orderByKey,filterValue){

  console.log(rbasePath,type,orderByKey,filterValue)
  // const rBaseCall = await get(child(rbaseRef(database),rbasePath));
  const queryRef = rbaseQuery(rbaseRef(database,rbasePath),orderByChild(orderByKey),equalTo(filterValue))
  

  // const rBaseCall = await get(child(rbaseRef(database),rbasePath));

  const rBaseCall = await get(queryRef);
  const  mapObj = rBaseCall.val()
  console.log(mapObj)



  //const filteredMapObj = Object.fromEntries(Object.entries(mapObj).filter(([objKey,map])=>map[orderByKey]==filterValue))



  // return filteredMapObj;
  return mapObj;
}


export async function getRbaseValueByPath(rbasePath){
  const rBaseCall = await get(child(rbaseRef(database),rbasePath));
  const  val = rBaseCall.val()
  console.log(val)
  return val;
}

export async function updateRbaseOnPath(rbasePath,dbInsertMap){

  const op = {
    status:false,
    message:"",
    errorMessage:null
  }

  try{
    await update(rbaseRef(database,rbasePath),dbInsertMap);
    op.status = true;
    op.message = "Update Successfull"
    return op
  }catch(error){
    op.errorMessage = error
    op.message="Update Failed"
    return op
  }
}

export async function updateFirestoreOnPath(firestoreCollPath,firestoreDocPath,dbInsertMap){

  const op = {
    status:false,
    message:"",
    errorMessage:null
  }

  const firestoreRef = doc(db, firestoreCollPath, firestoreDocPath)

  try{
    await updateDoc(firestoreRef,dbInsertMap);
    op.status = true;
    op.message = "Update Successfull"
    return op
  }catch(error){
    op.errorMessage = error
    op.message="Update Failed"
    return op
  }
}


export function getRbasePathForAccountSelection(rbasePathStr,navTreeNodeValMap){

  console.log(rbasePathStr,navTreeNodeValMap)

  const path = rbasePathStr.split("/").map((pathComp)=>{
    if(pathComp[0]=="$"){
      const keyComp = pathComp.slice(1);
      const actualPath = navTreeNodeValMap[keyComp]
      return actualPath
    }else{
      return pathComp
    }
  }).join("/")
  // const path = `${rbaseNodeName}/${navTreeNodeIds.join("/")}`
  return path;
}


export async function getInstituteNameByUid(instituteUid){
  const rBaseCall = await get(child(rbaseRef(
    database
  ),`institutes/${instituteUid}`));
  const  mapObj = rBaseCall.val()
  return mapObj.name;
}


function convertRbaseMapObjToArray(mapObj,nodeKeyName){

    console.log(mapObj)

    return Object.keys(mapObj).map((nodeKeyValue)=>{
        const map = mapObj[nodeKeyValue];
        map[nodeKeyName] = nodeKeyValue

        return map;
    })
}

export function getInitInpValMap(inpSchemeMap){
  const initInpValMap = {}
  inpSchemeMap.inpKeys.forEach((inpKey)=>initInpValMap[inpKey]="")

  return initInpValMap  
}




export function convertMapObjToArray(mapObj){

  console.log(mapObj)

  return Object.keys(mapObj).map((nodeKeyValue)=>{
      const map = mapObj[nodeKeyValue];
      return map;
  })


}



export function getBlankValueforInpList(list){
  switch(typeof list[0]){
    case "string": return ""
    case "number": return 0
    default:return false
  }
}


function flattenArray(array,uiTypes,mode){
  console.log(array,uiTypes,mode)
  const rArray = []

  for(const [index,uiType] of uiTypes.entries()){

      if(uiType=="dynamic" || uiType=="dynamic-small" || uiType=="fixed-row" ||  uiType=="dynamic-multi"){
      //[["ab","ba"],["sa","ma"],["cl","ad"]]

        rArray.push(...array[index]);

      }else if(uiType=="dynamic-nested"){
          //["workCategories","expInWorkCategories",["clientNames","workDescriptions","valueOfWorkOrders","workOrderAttachments","completionCertficateAttachments","workStatuses"]],

          if(mode=="value"){
                     //[["ab","ba"],["sa","ma"],[[[],[]],[[],[]],[[],[]]]]
                      // ["ab","ba"],["sa","ma"],[[],[]],[[],[]],[[],[]]

                      console.log(array[index])

                      for(const item of array[index]){
                          if (typeof item[0] == "object"){
                              console.log(...item)
                              rArray.push(...item);
                          }else{
                              rArray.push(item);
                          }
                      }

          }else{
              rArray.push(...[].concat(...array[index]))
          }

      }else{
              rArray.push(array[index])
      }

  }

  return rArray;

}


export function getOpPromptMap(inpSchemeMap,opFn,afterOpCompletionFn){
  return {
    status:false,
    title:inpSchemeMap.opLabel,
    message:inpSchemeMap.opConfirmationMsg,
    opBtnLabel:inpSchemeMap.opBtnLabel,
    opBtnAction:inpSchemeMap.opBtnAction,
    opSuccessMsg:inpSchemeMap.opSuccessMsg,
    afterOpCompletionFn:afterOpCompletionFn,
    "opFn": opFn
  }
}


export function getBtnColorBasedOnBtnAction(btnActionName){
  const casualBtnActions = []
  const normalBtnActions = ["addDbData"]
  const criticalBtnActions = ["addUser","editUser","editDbData","deleteDbData"];

  switch(true){
      case casualBtnActions.includes(btnActionName): return "green"
      case normalBtnActions.includes(btnActionName): return "primary"
      case criticalBtnActions.includes(btnActionName): return "red"
      default: return "black"
  }

}


export function generateInitValMapFromPreInpMap(blankValmap,preInpMap){

  const initValMap = {...blankValmap}

  Object.keys(preInpMap).forEach((key)=>{
    initValMap[key] = preInpMap[key]
  })

  return initValMap

}


export function createBlankValueMap(schemeMap){

  console.log(schemeMap)

  const valMap = {};

  const inpKeys = schemeMap.inpKeys
  const inpTypes = schemeMap.inpTypes
  // const flatInpKeys = flattenArray(schemeMap.inpKeys,schemeMap.inpUiTypes,"normal");
  // const flatInputTypes = flattenArray(schemeMap.inpTypes,schemeMap.inpUiTypes,"normal");
  // const dbSpKeys = schemeMap.dbSpKeys
  const inpUiTypes = schemeMap.inpUiTypes;

  for(const [i,inpUiType] of inpUiTypes.entries()){

    if(inpUiType=="dynamic" || inpUiType=="dynamic-multi" || inpUiType=="dynamic-nested" ||   inpUiType=="dynamic-small" || inpUiType=="fixed-row" ){
      const subInpKeys = inpKeys[i]
      for(const [j,subDbKey] of subInpKeys.entries()){
        if(typeof subDbKey=="object"){
          const nestInpKeys = inpKeys[i][j]
          for(const [h,nestDbKey] of nestInpKeys.entries()){
    
            valMap[nestDbKey]=[]
          }
        }else{

          valMap[subDbKey]=[]
        }

        if(inpUiType=="dynamic-nested"){
          if(j==subInpKeys.length-1){ //for static values
            valMap[subDbKey]=""
          }
        }
      }
    }else if(inpUiType=="conditional"){
      const subInpKeys = inpKeys[i]
      for(const [j,subDbKey] of subInpKeys.entries()){
        valMap[subDbKey]=""
      }

    }else if(inpUiType=="db-multi-checkbox"){
      valMap[inpKeys[i]]=[]
    }else{

        if(inpTypes[i]=="file"){
          valMap[inpKeys[i]]=false
        } if(inpTypes[i]=="number"){
          valMap[inpKeys[i]]=0
        }else{
          valMap[inpKeys[i]]=""
        }
       
      // }

    }
  }


  console.log(valMap)
  return valMap

}


export function generateBlankInpValdMap(schemeMap){
  console.log(schemeMap)
  const map = {};

  const inpKeys = schemeMap.inpKeys
  const inpTypes = schemeMap.inpTypes
  // const flatDbKeys = flattenArray(schemeMap.inpKeys,schemeMap.inpUiTypes,"normal");
  // const flatInputTypes = flattenArray(schemeMap.inpTypes,schemeMap.inpUiTypes,"normal");
  // const dbSpKeys = schemeMap.dbSpKeys
  const inpUiTypes = schemeMap.inpUiTypes;

  for(const [i,inpUiType] of inpUiTypes.entries()){

    if(inpUiType=="dynamic" || inpUiType=="dynamic-multi" || inpUiType=="dynamic-nested" ||   inpUiType=="dynamic-small" || inpUiType=="fixed-row" ){
      const subDbKeys = inpKeys[i]
      for(const [j,subDbKey] of subDbKeys.entries()){
        if(typeof subDbKey=="object"){
          const nestDbKeys = inpKeys[i][j]
          for(const [h,nestDbKey] of nestDbKeys.entries()){
            map[nestDbKey]=[]
          }
        }else{
          map[subDbKey]=[]
        }

        if(inpUiType=="dynamic-nested"){
          if(j==subDbKeys.length-1){ //for static values
            map[subDbKey]=true
          }
        }
      }
    }else if(inpUiType=="conditional"){
      const subDbKeys = inpKeys[i]
      for(const [j,subDbKey] of subDbKeys.entries()){
        map[subDbKey]=true
      }

    }else{
      map[inpKeys[i]]=true
       
      // }

    }
  }


  console.log(map)
  return map

}


export   function getStatSchemeMap(userRole){
  switch(userRole){
    case "Institute" : return statSchemeMapInstitute
    case "Principal" : return statSchemeMapPrincipal
    case "Super Admin" : return statSchemeMapSuperAdmin
    case "Student" : return statSchemeMapStudent
  }
}


export function getAppStage(appStage){

  switch(appStage){
    case "development" : return "DEVELOPMENT"
    case "production" : return ""
    default : return "UNKNOWN"
  }


}


export async function getSectionMarksDataMap(data){

  const navTreeNodeValMap = data.navTreeNodeValMap;
  const navTreeNodeDbMapObj = data.navTreeNodeDbMapObj;
  const instituteUid = navTreeNodeValMap.instituteUid
  const examName = navTreeNodeValMap.examName
  const className = navTreeNodeValMap.className
  const sectionName = navTreeNodeValMap.sectionName
  const instituteAcademicYear = data.navTreeNodeDbMapObj.institute.instituteAcademicYear


  console.log(instituteUid,examName,className,sectionName)

  const collQuery =  query(collection(db,`institutes/${instituteUid}/examMarks`),
  where("examName","==",examName),where("className","==",className),where("sectionName","==",sectionName),where("instituteAcademicYear","==",instituteAcademicYear));

  const collDocs = await getDocs(collQuery)

  console.log(collDocs,collDocs.size,collDocs.docs.length)

  let examMarkMap = {};

  let examDoc = null

  let dataMap={}

  dataMap["examDescription"] = navTreeNodeDbMapObj["exam"]["examDescription"]
  dataMap["totalMarksCount"] = navTreeNodeDbMapObj["exam"]["examMaxMarks"]

  if(collDocs.size!=0){
    examDoc = collDocs.docs[0].data();
    console.log(examDoc)
    examMarkMap = examDoc.studentExamMarksMap
  }

  // setTotalMarksCount(navTreeNodeDbMapObj["exam"]["examMaxMarks"]);

      const mapOfMapsRbaseCall = await get(child(rbaseRef(
        database
      ),`students/${instituteUid}/${className}/${sectionName}`));
          
      const  mapOfMaps = mapOfMapsRbaseCall.val()

      if(mapOfMaps){///if students are present

      console.log(mapOfMaps)

      const tColl = []

    Object.keys(mapOfMaps).forEach((key,i)=>{
            const tMap = {}
            tMap["uid"] = key;
            tMap["mark"] = key in examMarkMap ? examMarkMap[key] : 0;
            tMap["presentCond"] = key in examMarkMap ? true : false;
            tMap["studentRollNo"] = mapOfMaps[key].studentRollNo
            // tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
            tMap["name"] = mapOfMaps[key].name

            tColl.push(tMap);
          });

          const sortedStudents = naturalSortMapsByKey(tColl,"studentRollNo");

          dataMap["students"] = sortedStudents

        }else{
          dataMap["students"] = []
        }
        
          return dataMap
}


export async function getSectionAttendanceDataMap(data){

    console.log(data)

    const navTreeNodeValMap = data.navTreeNodeValMap;
    const instituteUid = navTreeNodeValMap.instituteUid
    const className = navTreeNodeValMap.className
    const sectionName = navTreeNodeValMap.sectionName
    const subjectName = navTreeNodeValMap.subjectName
    const instituteAcademicYear = data.navTreeNodeDbMapObj.institute.instituteAcademicYear

    const collQuery =  query(collection(db,`institutes/${instituteUid}/attendance`),
    where("className","==",className),where("sectionName","==",sectionName),where("subjectName","==",subjectName),where("instituteAcademicYear","==",instituteAcademicYear));

      const collDocs = await getDocs(collQuery)

      console.log(collDocs.size)

      let attendanceDoc = null

      console.log(collDocs,collDocs.size,collDocs.docs.length)

      let attendanceMap = {};

      let dataMap={}

      if(collDocs.size==0){

        dataMap["totalClassCount"] = 0
        dataMap["attendanceDate"] = null;


      }else{
              attendanceDoc = collDocs.docs[0].data();


              attendanceMap = attendanceDoc.studentAttendanceMap

              dataMap["totalClassCount"] = attendanceDoc.totalClassCount
              dataMap["attendanceDate"] = attendanceDoc.attendanceDate

              // setTotalClassCount(attendanceDoc.totalClassCount)
              // setAttendanceDate(attendanceDoc.attendanceDate)

        }

          const mapOfMapsRbaseCall = await get(child(rbaseRef(
            database
          ),`students/${instituteUid}/${className}/${sectionName}`));
              
          const  mapOfMaps = mapOfMapsRbaseCall.val()
          console.log(mapOfMaps)

          if(mapOfMaps){

          const tColl = []

          Object.keys(mapOfMaps).forEach((key,i)=>{
                  const tMap = {}
                  tMap["uid"] = key;
                  tMap["attendance"] = key in attendanceMap ? attendanceMap[key] : 0;
                  tMap["attendanceAvg"] = key in attendanceMap ? attendanceDoc ? getPercentage(tMap["attendance"],attendanceDoc.totalClassCount,2) : 0 : 0;
                  tMap["studentRollNo"] = mapOfMaps[key].studentRollNo
                  // tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
                  tMap["name"] = mapOfMaps[key].name

                  tColl.push(tMap);
                });

                

                const sortedStudents = naturalSortMapsByKey(tColl,"studentRollNo");

                console.log(tColl)

                dataMap["students"] = sortedStudents
              }else{
                dataMap["students"] = []
              }
          
          return dataMap


}



export async function generatePdfStudent(inpSchemeMap,dataMapObj,subject,data,output){

    console.log(dataMapObj)
  
    console.log(pdfFonts)
  
    // const base64Img = getBase64ImgFromImageComp(appLogoImg)


  
    const colors={
     lightGrey:"#f2f2f2",
     darkGrey:"#737373"
    }
   
    const lineWidths={
     outer:1
    }
   
   
      
   pdfMake.fonts = {  
   // download default Roboto font from cdnjs.com
   Roboto: {
   normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
   bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
   italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
   bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
   },
   
   // BlinkMacSystem: {
   //   normal:'Segoe UI.ttf',
   //   bold:'Segoe UI.ttf',
   //   italics:'Segoe UI.ttf',
   //   boldItalics:'Segoe UI.ttf'
   
   //   },
   }
   
   
   //Step 6c: Initialize Information to be put up
   
   pdfMake.tableLayouts = {
   
   
   
   noBorderSingleColumn: {
   hLineWidth: function (i, node) {           
   return 0
   },
   vLineWidth: function (i,node) {
   return 0
   //  return i == 0 ? 0 : 0.5
   },
   hLineColor: function (i) {
   return i === 1 ? '#bbb' : '#bbb';
   },
   paddingLeft: function (i) {
   return 0;
   },
   paddingRight: function (i, node) {
   return 0;
   },
   paddingTop: function (i, node) {
   return 8;
   },
   paddingBottom: function (i, node) {
   return 8;
   }
   },
   sectionHead: {
     hLineWidth: function (i, node) {           
     return 0.5;
     },
     vLineWidth: function (i,node) {
     return 0.5;
   
     },
     hLineColor: function (i) {
     return colors.darkGrey
     },
     vLineColor: function (i) {
       return colors.darkGrey
       },
     paddingLeft: function (i) {
     return 10;
     },
     paddingRight: function (i, node) {
     return 8;
     },
     paddingTop: function (i, node) {
     return 10;
     },
     paddingBottom: function (i, node) {
     return 10;
     }
     },
     subSectionHead: {
       hLineWidth: function (i, node) {           
       return 0.5;
       },
       vLineWidth: function (i,node) {
       return 0.5;
     
       },
       hLineColor: function (i) {
       return colors.darkGrey
       },
       vLineColor: function (i) {
         return colors.darkGrey
         },
       paddingLeft: function (i) {
       return 6;
       },
       paddingRight: function (i, node) {
       return 8;
       },
       paddingTop: function (i, node) {
       return 6;
       },
       paddingBottom: function (i, node) {
       return 6;
       }
       },
       
     simpleInpSection: {
       hLineWidth: function (i, node) {           
       return 0.5;
       },
       vLineWidth: function (i,node) {
       //return 0.5
       return 0.5
       },
       hLineColor: function (i) {
       return colors.grey
       },
       paddingLeft: function (i) {
       return 8;
       },
       paddingRight: function (i, node) {
       return 8;
       },
       paddingTop: function (i, node) {
       return 8;
       },
       paddingBottom: function (i, node) {
       return 8;
       }
       },
       rowSection: {
         hLineWidth: function (i, node) {           
         return 0.5;
         },
         vLineWidth: function (i,node) {
         return  0.5
         },
         hLineColor: function (i) {
         return colors.darkGrey
         },
         vLineColor: function (i) {
           return colors.darkGrey
           },
         paddingLeft: function (i) {
           return 4;
           },
           paddingRight: function (i, node) {
           return 4;
           },
           paddingTop: function (i, node) {
           return 8;
           },
           paddingBottom: function (i, node) {
           return 8;
           }
         },
         signatureSection: {
           hLineWidth: function (i, node) {           
           return 0.5;
           },
           vLineWidth: function (i,node) {
           return 0.5;
           },
           hLineColor: function (i) {
           return colors.darkGrey
           },
           vLineColor: function (i) {
             return colors.darkGrey
             },
           paddingLeft: function (i) {
           return 6;
           },
           paddingRight: function (i, node) {
           return 8;
           },
           paddingTop: function (i, node) {
           return 6;
           },
           paddingBottom: function (i, node) {
           return i==2? 100 : 6;
           }
           },
           singleSignatureSection: {
             hLineWidth: function (i, node) {           
             return 0.5;
             },
             vLineWidth: function (i,node) {
             return 0.5;
             },
             hLineColor: function (i) {
             return colors.darkGrey
             },
             vLineColor: function (i) {
               return colors.darkGrey
               },
             paddingLeft: function (i) {
             return 6;
             },
             paddingRight: function (i, node) {
             return 8;
             },
             paddingTop: function (i, node) {
             return 6;
             },
             paddingBottom: function (i, node) {
             return i==0? 75 : 6;
             }
             },

             minSpaceRowSection: {
              hLineWidth: function (i, node) {           
              return 0.5;
              },
              vLineWidth: function (i,node) {
              return  0.5
              },
              hLineColor: function (i) {
              return colors.darkGrey
              },
              vLineColor: function (i) {
                return colors.darkGrey
                },
              paddingLeft: function (i) {
                return 2;
                },
                paddingRight: function (i, node) {
                return 2;
                },
                paddingTop: function (i, node) {
                return 4;
                },
                paddingBottom: function (i, node) {
                return 4;
                }
              },
   
   };



   
   
   const docDefObject =  (()=>{
    switch(subject){
      case "studentExamMarks" : return generateStudentSheet(pdfGenerateSchemeMap.studentExamMarks,dataMapObj)
      case "studentAttendance" : return generateStudentSheet(pdfGenerateSchemeMap.studentAttendance,dataMapObj)
      case "examMarks" : return generateInstituteSectionSheet(pdfGenerateSchemeMap.examMarks,dataMapObj) 
      case "attendance" : return generateInstituteSectionSheet(pdfGenerateSchemeMap.attendance,dataMapObj)
    }
   })();
   
   
  
   const pageSize = 'A4'
   
   const docDefinition={
     content:docDefObject,
     pageSize:pageSize,
     pageOrientation:'portrait',
     pageMargins:[20,20,20,20]
   }
   
   
   const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  
   console.log(pdfDocGenerator)
   
   
   switch(output){
   
     case "download":{
   
       pdfDocGenerator.getBlob((blob) => {
         const link = document.createElement("a");
         link.href = URL.createObjectURL(blob);
       
     
         link.download = `sheet.pdf`;
         document.body.append(link);
       
         link.click();
         link.remove();
         setTimeout(() => URL.revokeObjectURL(link.href), 7000);
       
         });
       
         pdfDocGenerator.download()
   
     }
     break;
     case "dataUrl":{
   
       const dataUrl = await pdfGetDataUrl(pdfDocGenerator);
       console.log(dataUrl)
       return dataUrl
   
     }
     break;
     case "file":{
   
       const file = await pdfGetFile(pdfDocGenerator);
       console.log(file)
       return file
   
     }
   
   }
   
   }


   async function pdfGetDataUrl(pdfDoc){

    return new Promise((resolve,reject) =>{
  
      try{
    
      pdfDoc.getDataUrl((dataUrl)=>{
        return resolve(dataUrl)
      })
    
      }catch(e){
        return reject(e)
      }
    })
  }



async function pdfGetFile(pdfDoc){

    return new Promise((resolve,reject) =>{
  
      try{
    
      pdfDoc.getBlob((blob)=>{
        const date = new Date()
        const mills = date.getTime()
        const fileOptions = {
          type:"application/pdf"
        }
  
        const file = new File([blob],`sheet-${mills}.pdf`,fileOptions);
        return resolve(file)
      })
    
      }catch(e){
        return reject(e)
      }
    })
  }

  export function getSelectionOpacityStyleClass(disabled, selected) {

    return disabled ? "opacity-30" : "opacity-100"

  }


  export function getRegexPattern(category){
    switch(category){
      case "entityName": 
      //return new RegExp("^[A-Za-z0-9][A-Za-z0-9-][A-Za-z0-9]{1,}$")
      return new RegExp("^[A-Za-z@0-9]([A-Za-z@0-9&.]*[A-Za-z0-9]{0,}$|[A-Za-z@0-9-&. ]*[A-Za-z0-9]{1,}$)")
      break;
      case "personName":// matches person name or no string
        return new RegExp("^[A-Za-z]([A-Za-z]*[A-Za-z]{0,}$|[A-Za-z ]*[A-Za-z]{1,}$)|^$")
        break;
      case "email":
        return new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+(\\.{1}[A-Za-z]{2,}){0,1}\\.{1}[A-Za-z]{2,}$")
        break;
        case "phoneNo":
          return new RegExp("^\\+[0-9]{0,14}[0-9]$")
          break;
        case "number":
            return new RegExp("^[0-9]{1,}[0-9]{0,}$")
            
        case "phoneNoLocal":
          return new RegExp("^[0-9][0-9]{8}[0-9]$")
          break;
          case "number": return new RegExp("^[0-9]*$")
      case "password":
          return new RegExp("^[A-Za-z0-9@][A-Za-z0-9@]{4,}[A-Za-z0-9@]$")
          break;
      case "gstin":
          return new RegExp("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")
          break;
      case "latitude":
            return new RegExp("^-?[0-9]{2}.[0-9][0-9]+$")
            break;
      case "longitude":
              return new RegExp("^-?[0-1][0-9]{2}.[0-9][0-9]+$")
              break;
      case "sql-free":
            return new RegExp("")
             break;
      case "pastDate":
              // const currentYear = new Date().getFullYear();
              return new RegExp("")
               break;
      default:
        return new RegExp("")

    }
  }


  
