
import React, { useEffect, useState } from "react";
import {
  httpsCallable
} from "../Utils";
import ActionBtnContDyn from "./ActionBtnContDyn";
import BottomContainer from "./BottomContainer";
import BtnNormalCompact from "./BtnNormalCompact";
import BtnNormalSelAction from "./BtnNormalSelAction";
import ButtonIconTitle2 from "./ButtonIconTitle2";
import { naturalSortMapsByKey } from "./genFunctions";
import { getBtnImgSize } from "./HelperViewFns";
import { getRbasePathForAccountSelection, getRbaseValueByPath, getRbaseValueByQuery } from "./projectFns";
import { addMarksSelectionSchemeMap } from "./viewProps";
//import { ReactComponent as EmployeeStatIcon } from "../images/employee-stat.svg";
// const qrCode = window.require("qrcode");
// const PDFDocument = window.require("pdfkit");
// const blobStream = window.require("blob-stream");

//@start
export default function FnSelectFilterContModule(props) {

  console.log(props)
  const categories = props.schemeMap.categories

  const catDbKeys = props.schemeMap.catDbKeys
  const navTreeNodeKeys = props.schemeMap.navTreeNodeKeys

  const selectionTopTitleKeys = "selectionTopTitleKeys" in props.schemeMap ? props.schemeMap.selectionTopTitleKeys : categories.map((it)=>"")



  const [showActionBtnCont, setShowActionBtnCont] = useState(false);
  const [actionBtnMaps,setActionBtnMaps] = useState([{}])
  const [showSpinner, setShowSpinner] = useState(false);
  const [showPromptOp, setShowPromptOp] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [showModalBoxInput, setShowModalBoxInput] = useState(false);
  const [modalMap, setModalMap] = useState({});
  const [showPrompt, setShowPrompt] = useState(false);
  const [navTreeNodeIds,setNavTreeNodeIds] = useState([]);
  const [navTreeNodeValues,setNavTreeNodeValues] = useState([]);
  const [navTreeNodeValMap,setNavTreeNodeValMap] = useState({...props.passMap.initNodeValMap});
  const [navTreeNodeDbMapObj,setNavTreeNodeDbMapObj] = useState({});
  const [viewSequence,setViewSequence] = useState(0)

  const [students,setStudents] = useState([])

  const [showLoader,setShowLoader] = useState(false)



  const navFlowCond = true;
  const intermediateSelectCond = false;
  const navTreeLength = props.schemeMap.categories.length;



  // const [navTreeMaps, setNavTreeMaps] = useState([...getInitNavTreeMaps(props.passMap.navTreeStartIndex,categories)
  // ]);

  const [navTreeMaps, setNavTreeMaps] = useState([]);

  const [navTreePosIndex, setPosIndex] = useState(props.passMap.navTreeStartIndex);
  const [selUnitMap,setSelUnitMap]=useState({});


  useEffect(()=>{
    (async () => await initializeData())()

  },[])



  async function initializeData(){

    const startIndex = props.passMap.navTreeStartIndex


    const selectionSource = props.schemeMap.selectionSources[startIndex];

    setShowLoader(true);

    const dbMapObj = selectionSource=="memory" ?  props.listenMaps[props.schemeMap.selectionRbaseDataPath[startIndex]] : await getRbaseValueByPath(getRbasePathForAccountSelection(props.schemeMap.selectionRbaseDataPath[startIndex],props.passMap.initNodeValMap))




    const unitNavTreeMap={
      category:categories[props.passMap.navTreeStartIndex],
      parentUid: startIndex == 0 ? "NA" : props.passMap.parentUid,
      mapOfMaps:props.mapOfMaps,
      selections:[...generateSelectionsFromMapOfMaps(dbMapObj)]
    }



    console.log(unitNavTreeMap)

    const intiNavTreeMaps = [];
    const initNavTreeNodeIds = [];
    const initNavTreeNodeValMaps = [];
    const initNavTreeNodeDbMaps = []

    for(let x=0; x<=startIndex; x++){
      if(x<startIndex){
        intiNavTreeMaps.push({})
        initNavTreeNodeIds.push("")
        initNavTreeNodeDbMaps.push({})

        // tNavTreeNodeIds.push(selMap[navTreeNodeType])
        // tNavTreeNodeValMap[navTreeNodeKey] = selMap[navTreeNodeType]
        // tNavTreeNodeDbMap[catDbKey] = selMap["dbMap"]

      }else{
        intiNavTreeMaps.push(unitNavTreeMap)
      }
    }
    
    setNavTreeMaps(intiNavTreeMaps)
    setNavTreeNodeIds(initNavTreeNodeIds)
    // setNavTreeNodeValMap(init)


//For Smaller Scope Access Roles like Institute
    const initNavTreeNodeDbMapObj = await getNavTreePreSelectionMaps(addMarksSelectionSchemeMap,startIndex,props.passMap.initNodeValMap)

    async function getNavTreePreSelectionMaps(schemeMap,navTreeStartIndex,dataMap){
        const tNavTreeNodeDbMapObj = {}

        for(let x=0; x<navTreeStartIndex; x++){
          const preSelectionRbasePath = schemeMap.preSelectionRbaseDataPaths[x];
          const catDbKey = schemeMap.catDbKeys[x];

          const nodeDbMapOfIndex = await getRbaseValueByPath(getRbasePathForAccountSelection(preSelectionRbasePath,dataMap));

          tNavTreeNodeDbMapObj[catDbKey] = nodeDbMapOfIndex;
        }

        return tNavTreeNodeDbMapObj
    }

  setShowLoader(false);
    
   setNavTreeNodeDbMapObj(initNavTreeNodeDbMapObj)

  }






  const navTreeStartIndex = props.passMap.navTreeStartIndex;

  const selectionFilterColor = "invert(10%) sepia(65%) saturate(7439%) hue-rotate(5deg) brightness(99%) contrast(117%)"

  console.log(props)

  const bottomContainer = (
    <BottomContainer backBtnClickHandler={() => props.backBtnClickHandler()} />
  );


  //@2
  async function functions(fnName,data){
  
    console.log(data)

    let dialogTitle = "" 
    let dialogMsg = ""
    let opBtnLabels = ""
    let opSuccessMsgs  = ""
    let opBtnAlertLevels = ""

    const sPosIndex = data.posIndex;
    const sNavTreeMaps = "navTreeMaps" in data ? data.navTreeMaps : [];
    const selMap = "selMap" in data ? data.selMap : {};
    const navTreeNodeIds = "navTreeNodeIds" in data ? data.navTreeNodeIds : []
    const navTreeNodeValMap = "navTreeNodeValMap" in data ? data.navTreeNodeValMap : {}
    const navTreeNodeDbMapObj = "navTreeNodeDbMapObj" in data ? data.navTreeNodeDbMapObj : {}

    switch(fnName){
      case "select":{

        const catDbKey = props.schemeMap.catDbKeys[sPosIndex];
        const tNavTreeNodeDbMap = {...navTreeNodeDbMapObj}

        console.log(selMap)

        const actionBtnMaps = props.schemeMap.selectionOptionsSchemeMapObj[selMap.category].btnMaps;

        //for inputting the data of the selection, better to add it now because during select there may be many functions performed and hence, we need to add the selection dbMap at those points
        tNavTreeNodeDbMap[catDbKey] = selMap["dbMap"]
        setNavTreeNodeDbMapObj(tNavTreeNodeDbMap)
  
        setSelUnitMap(selMap);
        setShowActionBtnCont(true);
        setActionBtnMaps(actionBtnMaps)
        updateNavTreeMaps(sPosIndex,sNavTreeMaps,selMap,"select")
      }
      break;
      case "enter":{
        console.log(data)
        let proceedToEnter = true;
        console.log(selMap)
        const navTreeNodeType = props.schemeMap.navTreeNodeTypes[sPosIndex]; //ex - name, uid
        const catDbKey = props.schemeMap.catDbKeys[sPosIndex];
        const navTreeNodeKey = props.schemeMap.navTreeNodeKeys[sPosIndex];
        const tNavTreeNodeIds = [...navTreeNodeIds]
        const tNavTreeNodeValMap = {...navTreeNodeValMap}
        const tNavTreeNodeDbMap = {...navTreeNodeDbMapObj}
  
        console.log(navTreeNodeIds,sPosIndex)
  
        if(tNavTreeNodeIds.length == sPosIndex){ //destination tree has not been navigated to beofore, this condnto check if the next tree has already been navigated before or not, if it has navigated before then the condition will be false else will be true
  
          tNavTreeNodeIds.push(selMap[navTreeNodeType])
          tNavTreeNodeValMap[navTreeNodeKey] = selMap[navTreeNodeType]
          tNavTreeNodeDbMap[catDbKey] = selMap["dbMap"]
  
          console.log(tNavTreeNodeValMap,navTreeNodeKey)
  
        }else{ // tree has been navigated before
          tNavTreeNodeIds.splice(sPosIndex,1,selMap[navTreeNodeType])
          tNavTreeNodeValMap[navTreeNodeKey] = selMap[navTreeNodeType]
          tNavTreeNodeDbMap[catDbKey] = selMap["dbMap"]
        }
  
        {
  
        }
  
        console.log(tNavTreeNodeIds,tNavTreeNodeValMap,tNavTreeNodeDbMap)
        setNavTreeNodeIds(tNavTreeNodeIds)
        setNavTreeNodeValMap(tNavTreeNodeValMap)
        setNavTreeNodeDbMapObj(tNavTreeNodeDbMap)
  
        // const childCategory = ["Class","Section","Student"];
        // const categoryChildMaps = ["fetchFromDb_classes","fetchFromDb_sections","fetchFromDb_students"];
  
        // if(categories.includes(category)){
  
        //   const index = categories.indexOf(category);
  
        //   const categoryUid = selMap.uid;
        //   const childMap = categoryChildMaps[index]
        //   const childCount = categoryUid in props.listenMaps[childMap] ? Object.keys(props.listenMaps[childMap][categoryUid]).length : 0;
  
        //   if(childCount==0){
        //     setPromptMap({
        //       status:false,
        //       title:`No ${childCategory[index]}`,
        //       message:`There is no ${childCategory[index]} under this ${category}`,
        //       opBtnAlertLevels:[["low"]],
        //     })
  
        //     proceedToEnter = false;
  
        //     setShowPrompt(true)
  
        //    }
        // }
  
  
        const endOfSelectionCond = sPosIndex == navTreeLength-1;
  
  
        if(proceedToEnter){
          if(endOfSelectionCond){
            props.viewChangeFnHandler(props.schemeMap.topicFnName,{...data,navTreeNodeValMap:tNavTreeNodeValMap,navTreeNodeDbMapObj:tNavTreeNodeDbMap})
            // props.fnHandler("initializeData",{...data,navTreeNodeValMap:tNavTreeNodeValMap,navTreeNodeDbMapObj:tNavTreeNodeDbMap})
            setViewSequence(1)
            // setPosIndex(sPosIndex+1)
          }else{
            updateNavTreeMaps(sPosIndex+1,sNavTreeMaps,selMap,"",tNavTreeNodeIds,tNavTreeNodeValMap)
          }
       
        }
      }
      break;
      case "back":{
        setShowActionBtnCont(false)
        console.log(sPosIndex)
        const tNavTreeNodeIds = [...navTreeNodeIds]
        
        tNavTreeNodeIds.pop()
        setNavTreeNodeIds(tNavTreeNodeIds)
     
        updateNavTreeMaps(sPosIndex-1,sNavTreeMaps,selMap,"",tNavTreeNodeIds)
      }
      break;
      case "backViewSequence":{
        setViewSequence(0)
        // setPosIndex(sPosIndex-1) // as we increased the posIndex when transitioning to the next sequence
      }
      break;
      case "selectActionBtnClick":{

        const actionBtnMap = data.actionBtnMap;

     
        if(actionBtnMap.viewCond){
          setViewSequence(1)
          props.viewChangeFnHandler(actionBtnMap.btnFnName,{...data,
            viewFnLabel:actionBtnMap.viewLabel
           })
        }else{
     
         await props.fnHandler(actionBtnMap.btnFnName,data)
   
        }

      }
      break;
      case "selectActionClick":{
        console.log(data)
        const clickedBtn = data.btnSchemeMap.clickedBtnLabel;
        if(clickedBtn==props.schemeMap.selectionBtnLabels[0]){//add or edit marks
          setViewSequence(1)
        }else if(clickedBtn=="Enter"){
          functions("enter",data)
        }
      }
      break;
    }

  }



  function displayPrompt(message,title,status){
    setShowSpinner(false)
    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title ? title : "Info",
      message:message
    })
  }


  async function disableAccount(map) {
    const serverCall = httpsCallable(functions, "disableUser");
    const result = await serverCall({ uid: map.uid });
    alert(result.data);
  }

 


  async function updateNavTreeMaps(newPosIndex,navTreeMaps,selMap,action,navTreeNodeIds,navTreeNodeValMap){

    console.log(selMap,navTreeNodeValMap)
    console.log(newPosIndex)

   // const tNavTreeMaps = JSON.parse(JSON.stringify(navTreeMaps))
   const tNavTreeMaps = [...navTreeMaps]
    const selUid = selMap.uid;
   

    if(navTreeMaps.length-1 > newPosIndex){//it means back is pressed

      tNavTreeMaps.pop()
      const curNavTreeMap = tNavTreeMaps[newPosIndex]

      // if(newPosIndex==0){
      //   setShowActionBtnCont(false)
      //   tNavTreeMaps.push(curNavTreeMap)
      // }else{
      const selectionMaps =  curNavTreeMap.selections;
      curNavTreeMap["selections"] = updateSelections(selectionMaps,"default");
      tNavTreeMaps.pop()
      tNavTreeMaps.push(curNavTreeMap)
      // }

    }else if(navTreeMaps.length-1 == newPosIndex){//this is to stay in the same heirarchy level and perform changes in the present list of accounts - like select, disable, enable
      console.log(navTreeMaps)
      const curNavTreeMap = tNavTreeMaps[navTreeMaps.length-1]

      const mapOfMaps =  navTreeMaps[newPosIndex].mapOfMaps

      curNavTreeMap["selections"] = updateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex,selUid,action);

      tNavTreeMaps.pop()
      tNavTreeMaps.push(curNavTreeMap)

    }else if(newPosIndex > navTreeMaps.length-1){//going deeper the heirerarchy - enter
      setShowActionBtnCont(false)

      const seqUnitMap = {}
      seqUnitMap["category"] = categories[newPosIndex];
      seqUnitMap["parentUid"] = selUid;


      let mapOfMaps = {}

      const selectionSource = props.schemeMap.selectionSources[newPosIndex];

      //adding the next navTreeMap based on the selection
      if(selectionSource == "rbaseByPath" || selectionSource == "rbaseByQuery"){
        const rbasePathStr = props.schemeMap.selectionRbaseDataPath[newPosIndex]
        //const rbasePath = getRbasePathForAccountSelection(rbaseNodeName,navTreeNodeIds);
        const rbasePath = getRbasePathForAccountSelection(rbasePathStr,navTreeNodeValMap);

        console.log(rbasePath);

        const navTreeNodeKey = newPosIndex>0 ? props.schemeMap.navTreeNodeKeys[newPosIndex-1] : "NA"
        const navTreeNodeId = newPosIndex>0 ? navTreeNodeIds[newPosIndex-1] : "NA"

        console.log(navTreeNodeKey,navTreeNodeValMap[navTreeNodeKey])
        setShowLoader(true);
        try{
              mapOfMaps = selectionSource == "rbaseByQuery" ? await getRbaseValueByQuery(rbasePath,"orderByChild",navTreeNodeKey,navTreeNodeValMap[navTreeNodeKey]) : await getRbaseValueByPath(rbasePath)
              console.log(mapOfMaps)
      
        }catch(error){
          console.log("rbase fetch error")
        }
        setShowLoader(false);

      }



      console.log(selUid)
      console.log(mapOfMaps)


      // if(categories[newPosIndex]=="Student"){

      //   const tMapOfMaps = naturalSortMapObjByKey(mapOfMaps,"studentRollNo")
      //   console.log(tMapOfMaps)

      // }


      seqUnitMap["mapOfMaps"] = mapOfMaps
      seqUnitMap["selections"] = mapOfMaps ? generateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex) : [];

      console.log(seqUnitMap.selections)

      tNavTreeMaps.push(seqUnitMap)
    }

    console.log(tNavTreeMaps)

    setPosIndex(newPosIndex)
    setNavTreeMaps(tNavTreeMaps);
  }

  function convertSelectionsToMapOfMaps(selections){
    console.log(selections)
    const mapOfMaps={};
    selections.forEach((map,i)=>{
      mapOfMaps[map.uid] = map;
    })
  }
  
function generateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex){

  console.log(mapOfMaps,newPosIndex)

  const tColl = []

  if(mapOfMaps){
        
  Object.keys(mapOfMaps).forEach((key,i)=>{
    const tMap = {}
    tMap["uid"] = key;
    tMap["name"] = mapOfMaps[key].name
    tMap["isDisabled"] = mapOfMaps[key].isDisabled
    tMap["isSelected"] = false;
    tMap["category"] = categories[newPosIndex]
    tMap["dbMap"] = mapOfMaps[key]

    tMap["name2"] = mapOfMaps[key][selectionTopTitleKeys[newPosIndex]]

    tColl.push(tMap);
  });

  console.log(tColl)

  if(categories[newPosIndex]=="Student"){
    const sortedTColl = naturalSortMapsByKey(tColl,"name2")
    return sortedTColl
  }

  return tColl;

}else{
  return []
}
}

function updateSelectionsFromMapOfMaps(mapOfMaps,newPosIndex,selUid,action){
  console.log(mapOfMaps,newPosIndex,selUid,action)

  const tColl = []
        
  Object.keys(mapOfMaps).forEach((key,i)=>{
    const tMap = {}
    tMap["uid"] = key;
    tMap["navTreeNodeKey"] = navTreeNodeKeys[newPosIndex];
    tMap["name"] = mapOfMaps[key].name
    tMap["isSelected"] = selUid==key ? true : action=="default" ? mapOfMaps[key].isSelected : false; 
    tMap["isDisabled"] = (action=="enable" || action=="disable") && selUid==key ? action=="disable" : mapOfMaps[key].isDisabled
    tMap["category"] = categories[newPosIndex]
    tMap["dbMap"] = mapOfMaps[key]

    tMap["name2"] = mapOfMaps[key][selectionTopTitleKeys[newPosIndex]]

    if(selUid==key){
      setSelUnitMap(tMap)
    }

    if(action=="default" && mapOfMaps[key].isSelected){
      console.log(tMap)
      setSelUnitMap(tMap)
    }

    tColl.push(tMap);
  });

  console.log(tColl)

  if(categories[newPosIndex]=="Student"){
    const sortedTColl = naturalSortMapsByKey(tColl,"name2")
    return sortedTColl
  }

  return tColl;
}


function updateSelections(maps,action){
  console.log(maps)
        
  return maps.map((it,i)=>{

    console.log(it,i,action)
    // it["isSelected"] = action=="default" ? maps[i].isSelected : false; 
    it["isSelected"] = false; 

    // if(action=="default" && maps[i].isSelected){
    //   console.log(it)
    //   setSelUnitMap(it)
    // }
    return it
  });
}




function getBreadcrumbs(navTreeNodeDbMapObj,navTreePosIndex,viewSequence){
    
  console.log(navTreeNodeDbMapObj,navTreePosIndex)

    const breadCrumbComps = []

    const navTreeStartIndex = props.passMap.navTreeStartIndex 

    // if(props.passMap.navTreeStartIndex > 0){
    //   return ""
    // }


  
  for(let x=navTreeStartIndex;x<=navTreePosIndex;x++){
    const category = props.schemeMap.catDbKeys[x]
    
    if(x!=navTreePosIndex){
      breadCrumbComps.push(navTreeNodeDbMapObj[category]["name"])
    }else if(x==navTreePosIndex && viewSequence==1){
      breadCrumbComps.push(navTreeNodeDbMapObj[category]["name"])
    }
  }


 return breadCrumbComps.join("/")


}

//@return
  return (

    <div className={`relative h-full`}>
      {showLoader && <div className="absolute h-full w-full flex flex-center"><div className="loader-primary"></div></div>}
      {viewSequence==0 && //Selection Screen
      <div className="h-full m-4 grid grid-rows-cust-3c rounded bg-secondaryTransparent10 border-std ">
        <div className="relative text-xl flex justify-center">
          <div className="absolute top-0 left-0 p-4">
                <div className="text-2xl font-bold text-primary">{`Select ${categories[navTreePosIndex]}`}</div>
                <div className="gap-2 font-bold text-secondaryDark text-sm">{
                      getBreadcrumbs(navTreeNodeDbMapObj,navTreePosIndex,viewSequence)
                      }
                </div>
         </div>
        </div>
        <div className="flex flex-wrap items-center justify-center gap-8 overflow-y-auto p-4">
          {/* {renderSelections(navTreePosIndex)} */}
          {navTreeMaps.length > 0 && navTreeMaps[navTreePosIndex].selections.length != 0 && navTreeMaps[navTreePosIndex].selections.map((map,i)=>
                    <ButtonIconTitle2
                    key={"uid" in map ? map.uid : `${map.name}${i}`}
                    isDisabled={map.isDisabled}
                    btnColorClass={""}
                    section="Selection"
                    btnColor={map.isSelected ? "green" : "primary"}
                    btnTitle={map.name}
                    btnTitle2={map.name2}
                    btnImgSize={getBtnImgSize("Dashboard Function")}
                    btnImg={props.schemeMap.selectionImgs[navTreePosIndex]}
                    //clickHandler={(e) => selectionFn(e, map, i)
                    clickHandler={(e)=>functions(getUnitClickCourseAction(navTreePosIndex),{event:e,posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,selMap:map,navTreeNodeIds:navTreeNodeIds,navTreeNodeValMap:navTreeNodeValMap,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}
                    ></ButtonIconTitle2>
          )}
          {(navTreeMaps.length == 0 || navTreeMaps[navTreePosIndex].selections.length==0) && 
          <span>No Records Found</span> 
          }
 
        </div>
        <div key={`skksks`}  className="text-xl flex justify-center items-center gap-x-8">
          {showActionBtnCont && 
          <ActionBtnContDyn 
            topic={props.passMap.topic} 
            selAccMap={selUnitMap}
            btnLabels={[]}
            fnHandler={(fnName,data)=>functions(fnName,{btnSchemeMap:data,posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,selMap:selUnitMap,navTreeNodeIds:navTreeNodeIds,navTreeNodeValMap:navTreeNodeValMap,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}
            closeContHandler={()=> setShowActionBtnCont(false)}/>} 
          {navTreePosIndex > navTreeStartIndex &&
            <BtnNormalSelAction spClass="border border-primary text-primary text-sm" color="" label="Back" clickHandler={() => functions("back",{posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,navTreeNodeIds:navTreeNodeIds,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>}

        </div>
      </div>
      }
      {viewSequence==1 && 
            <div className="h-full m-4 grid grid-rows-cust-3c rounded bg-secondaryTransparent10 border-std">
              {/* <div className="text-xl flex justify-center">
                <div className="absolute top-0 left-0 text-2xl font-bold text-primary p-4 ml-4">{props.topicFnLabel}</div>
              </div> */}
           <div className="relative text-xl flex justify-center">
              <div className="absolute top-0 left-0 p-4">
                    <div className="text-2xl font-bold text-primary">{props.topicFnLabel}</div>
                    <div className="gap-2 font-bold text-secondaryDark text-sm">{
                          getBreadcrumbs(navTreeNodeDbMapObj,navTreePosIndex,viewSequence)
                          }
                    </div>
            </div>
          </div>
            {props.topicFnView()}
            {/* <div className="flex items-center justify-center gap-8">
            </div> */}
            <div key={`skksks`}  className="text-xl flex justify-center items-center gap-x-8">
              {showActionBtnCont && 
              <ActionBtnContDyn 
                topic={props.passMap.topic} 
                selAccMap={selUnitMap}
                btnLabels={props.schemeMap.selectionBtnLabels}
                fnHandler={(fnName,data)=>functions(fnName,{btnSchemeMap:data,posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,selMap:selUnitMap,navTreeNodeIds:navTreeNodeIds})}
                closeContHandler={()=>setShowActionBtnCont(false)}/>} 
              {navTreePosIndex > navTreeStartIndex &&
                <BtnNormalSelAction spClass="border border-primary text-primary text-sm" color="" label="Back" clickHandler={() => functions("backViewSequence",{posIndex:navTreePosIndex,navTreeMaps:navTreeMaps,navTreeNodeIds:navTreeNodeIds})}/>}  
            </div>
          </div>
      }
      <div className="absolute right-10 top-10 flex gap-2">
        {viewSequence==0 && showActionBtnCont==true && actionBtnMaps.length > 0 && 
                <div className="flex gap-2">
                  {actionBtnMaps.map((btnMap)=>
                  <BtnNormalCompact color={btnMap.btnColor} spClass="" label={btnMap.btnLabel} clickHandler={()=>functions("selectActionBtnClick",{selMap:selUnitMap,navTreeNodeDbMapObj:navTreeNodeDbMapObj,navTreeNodeValMap:navTreeNodeValMap,actionBtnMap:btnMap})}/>
                  )}
                </div>
          }
        {viewSequence==1 && props.topicFnBtnView(navTreeNodeValMap,navTreeMaps,navTreeNodeDbMapObj)
            //  <React.Fragment>
            //     <BtnNormal spClass="" color="greenDark" label="Save" clickHandler={()=> props.fnHandler("saveMarks",{students:students,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
            //     <BtnNormal spClass="" color="secondaryDark" label="Generate Sheet" clickHandler={()=>  props.fnHandler("generateMarksSheet",{students:students,navTreeNodeValMap:navTreeNodeValMap,navTreeMaps:navTreeMaps,navTreeNodeDbMapObj:navTreeNodeDbMapObj})}/>
            //   </React.Fragment>
        }

        <BtnNormalCompact spClass="" color="black" label="Back to Dashboard" clickHandler={()=> props.backBtnClickHandler()}/>
      </div>

    </div>



);

function getUnitClickCourseAction(navTreePosIndex){

  const selectionOutcome = props.schemeMap.selectionOutcomes[navTreePosIndex];

  if(selectionOutcome=="select"){
       return "select"
  }else{
      return "enter"
  }

}

}



function getColorClass(disabled, selected, absent) {
  console.log(`disabled=${disabled}, selected=${selected}, absent=${absent}`)
  if (disabled) {
    if (selected) {
      return "svg-pink";
    }
    return "svg-grey-light";
  } else {
    if (absent) {
      if (selected) {
        return "svg-red";
      }
      return "svg-grey";
    } else {
      if (selected) {
        //return "svg-red";
        return "svg-grey";
      }
      return "";
    }
  }
}

