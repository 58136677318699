import React, { useEffect, useState } from "react";
//import { useNavigate } from 'react-router-dom';
//import { initializeApp } from "firebase/app";
import {
  fetchAndActivate,
  getAuth,
  getValue,
  remoteConfig,
  styleProxyMap,
  useNavigate
} from "../Utils";
import BtnNormal from "./BtnNormal";
import { getFirestoreDocKeyValue } from "./DbHelperFns";
import { getAppStage, getDbInpOptions } from "./projectFns";

//import { getFunctions, httpsCallable } from "firebase/functions";

//import { check } from "../../functions";
//import { firebaseConfig } from '../firebaseWebConfiguration';
//import {getAuth,signInWithEmailAndPassword} from "firebase/auth";

export default function LoginSelection(props) {
  const auth = getAuth();

  const navigate = useNavigate();
  const [showPromptOp, setShowPromptOp] = useState(false);
  const [showPromptOp2, setShowPromptOp2] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showSpinner,setShowSpinner]=useState(false);
  const [remoteValMap,setRemoteValMap] = useState({})
  const [instituteOptionMaps,setInstituteOptionMaps] = useState([{}])
  const [instituteSelected,setInstituteSelected] = useState("")

  useEffect((()=>{

    //const val = getValue(remoteConfig,"check_email_verification")

    (async ()=>await functions("initializeData",{}))()

    fetchAndActivate(remoteConfig).then((valA)=>{
      console.log("remoteConfig Value",getValue(remoteConfig,"check_email_verification").asBoolean())

      const remoteValue = getValue(remoteConfig,"check_email_verification").asBoolean()
      
      setRemoteValMap({
        checkEmailVerification:remoteValue
      })

    }).catch((err)=>{
      console.log("error in remoteFetch",err)
    })


  }),[])


  async function functions(fnName,data){
    
    switch(fnName){
      case "initializeData":{
        try{
        const dbSelectionSchemeMap = {
          "key":"instituteUid",
          "attributeMapKey":"institutes",
          "rbasePath":"institutes",
          "rbaseNodeKeyName":"instituteUid",
          "selectOptionsValueKey":"instituteUid",
          "selectOptionsLabelKey":"name"
        }

        const selectionsFromDb = await getDbInpOptions(dbSelectionSchemeMap,{})

        console.log(selectionsFromDb)

        const labelKeyName = dbSelectionSchemeMap.selectOptionsLabelKey
        const valueKeyName = dbSelectionSchemeMap.selectOptionsValueKey

        const finalSelectionMaps = selectionsFromDb.map((selectionMap)=>{return {label:selectionMap[labelKeyName],value:selectionMap[valueKeyName]}})

        setInstituteOptionMaps(finalSelectionMaps)

      } catch (err) {
        console.log(err)
        console.log('Error Initializing Page Data');
      }
      }
      break;
      case "inputChangeFn":{

        const inputVal = data.event.target.value;
        setInstituteSelected(inputVal)
      }
      break;
      case "submitClickFn":{

        const instituteUid = data.instituteUid;

        const instituteShortName = await getFirestoreDocKeyValue(`institutes/${instituteUid}`,"instituteShortName");

         navigate("/login",{state:{instituteUid:data.instituteUid,instituteShortName:instituteShortName}})
 

      }
    }

  }


  return (
    <div className={`h-screen flex flex-col items-center justify-center ${styleProxyMap[props.backgroundColor]}`}>
      <div className="absolute top-10 left-10 text-red text-lg">{getAppStage(process.env.REACT_APP_MODE)}</div>

      <div className="sm:px-4">
        <div className="flex flex-center text-primary text-4xl font-bold p-12">SELECT INSTITUTE</div>
        <div className="flex gap-8 items-center">
        <label>Institute Name</label>
        <select
          className="pl-2"
          name="instituteSelected"
          onChange={(e)=>functions("inputChangeFn",{event:e})}
          // defaultValue={props.fieldValue}
          value={instituteSelected}
        >
          <option value="">Please Select</option>
          {instituteOptionMaps.map((optionMap, i) => (
            <option key={optionMap.value} value={optionMap.value}>
              {optionMap.label}
            </option>
          ))}
        </select>
        <BtnNormal color="green" label="Go" clickHandler={(e)=>functions("submitClickFn",{instituteUid:instituteSelected})}/>
        </div>
      </div>
      {process.env.REACT_APP_MODE=="development" && 
      <div className="absolute bottom-5 right-5">
        <BtnNormal color="secondaryDark" label="Admin Login" clickHandler={()=>navigate("/adminLogin")}/>
      </div>
      }
    </div>
  );
}
