import { equalTo, orderByChild, query as rbaseQuery, ref as rbaseRef, serverTimestamp } from "firebase/database";
import { collection, query, where } from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import {
  addSuperAdminInputObj,
  auth,
  database,
  db,
  deleteDoc,
  doc,
  editSettingsStoreBranchInputSchemeMap,
  editSettingsStoreBrandInputSchemeMap,
  editSettingsSuperAdminInputSchemeMap,
  fetchAndActivate,
  functions,
  getDoc,
  getModalInteractiveSchemeMap,
  getValue,
  httpsCallable,
  onAuthStateChanged,
  onSnapshot,
  onValue,
  remoteConfig,
  strMap,
  updateDoc,
  useNavigate,
  viewDashboardBlankSchemeMap,
  viewDashboardStoreBrandSchemeMap,
  viewSignUpRequestAdminSchemeMap
} from "../Utils";
import BannerListCont from "./BannerListCont";
import BannerListFilterCont from "./BannerListFilterCont";
import BannerListListenerCont from "./BannerListListenerCont";
import BannerListListenerCont2 from "./BannerListListenerCont2";
import BatchInputContStudents from "./BatchInputContStudents";
import ButtonFilterStripListCont from "./ButtonFilterStripListCont";
import ButtonStripListCont from "./ButtonStripListCont";
import ChangePassword from "./ChangePassword";
import ChartCont from "./ChartCont";
import ChartCont2 from "./ChartCont2";
import ChartCont3 from "./ChartCont3";
import ChartCont4 from "./ChartCont4";
import ChartCont5 from "./ChartCont5";
import Dashboard from "./Dashboard";
import DetailsActionBtnCont from "./DetailsActionBtnCont";
import DetailsCont from "./DetailsCont";
import Dialog from "./Dialog";
import FilterStripListCont from "./FilterStripListCont";
import FilterStripListContStores from "./FilterStripListContStores";
import FnSelectCont from "./FnSelectCont";
import FnSelectCont2 from "./FnSelectCont2";
import FnSelelectCont6 from "./FnSelectCont6";

import FnSelelectFilterCont from "./FnSelectFilterCont";
import FnSelelectFilterCont2 from "./FnSelectFilterCont2";
import FnSelelectFilterContAttendance from "./FnSelectFilterContAttendance";
import FnSelelectFilterContMarks from "./FnSelectFilterContMarks";
import FnSelelectFilterContOthers from "./FnSelectFilterContOthers";
import FnSelelectFilterContStudents from "./FnSelectFilterContStudents";
import FnSelelectContManageInstitute from "./FnSelelectContManageInstitute";
import { getPercentage } from "./genFunctions";
import InputCont from "./InputCont";
import InputTabCont from "./InputTabCont";
import InputWithControllerContInsertDb from "./InputWithControllerContInsertDb";
import ListCont2 from "./ListCont2";
import ListCont from "./ListContOld";
import LoadSpinner from "./LoadSpinner";
import ModalBoxAppInfo from "./ModalBoxAppInfo";
import ModalBoxExcel from "./ModalBoxExcel";
import ModalBoxInteractive from "./ModalBoxInteractive";
import ModalBoxMessagePrompt from "./ModalBoxMessagePrompt";
import ModalBoxOpConfPrompt from "./ModalBoxOpConfPrompt";
import { getRbaseValueByPath, getRoleDocPath } from "./projectFns";
import RecordBannerFilterCont from "./RecordBannerFilterCont";
import SettingsTabCont from "./SettingsTabCont";
import SpreadsheetBatchInputCont from "./SpreadsheetBatchInputCont";
import StripListBtnCont from "./StripListBtnCont";
import StripListBtnCont2 from "./StripListBtnCont2";
import StripListBtnListenerCont from "./StripListBtnListenerCont";
import StripListCont from "./StripListCont";
import StripListFilterBtnCont from "./StripListFilterBtnCont";
import StripListFilterBtnCont2 from "./StripListFilterBtnCont2";
import StripListFilterBtnCont3 from "./StripListFilterBtnCont3";
import StripListFilterCont from "./StripListFilterCont";
import StripListFilterCont2 from "./StripListFilterCont2";
import StripListFilterCont3 from "./StripListFilterCont3";
import StripListFilterContViewAttendance from "./StripListFilterContViewAttendance";
import StripListFilterContViewMarks from "./StripListFilterContViewMarks";
import TestContFirestore from "./TestContFirestore";
import TopBar from "./TopBar";
import { addAttendanceSelectionSchemeMap, addExamInpSchemeMap, addExamInpSchemeMapInstitute, addInstituteInpSchemeMap, addMarksSelectionSchemeMap, addPrincipalInpSchemeMap, addStudentInpSchemeMap, addStudentsBatchPreInpSchemeMap, addSubjectInpSchemeMap, addSuperAdminInpSchemeMap, manageExamSelectionSchemeMap, manageInstituteSelectionSchemeMap, manageStudentsSelectionSchemeMap, studentSelectionSchemeMap, viewAttendanceSelectionSchemeMap, viewDashboardInstituteSchemeMap, viewDashboardMasterSchemeMap, viewDashboardPrincipalSchemeMap, viewDashboardStudentSchemeMap, viewDashboardSuperAdminSchemeMap, viewMarksSelectionSchemeMap, viewStudentAttendanceAdvanceSchemeMap, viewStudentMarksListAdvanceSchemeMap, viewStudentsSelectionSchemeMap } from "./viewProps";


// const auth = getAuth(firebase);

async function getUserRole() {
  return await auth.currentUser.getIdTokenResult();
}

export const UserDataContext = createContext({});

//Function Component Starts
//@start
export default function Level1() {

  const productionMode = process.env.REACT_APP_MODE == "production";

  const allowedRoles = ["Institute","Student","Super Admin","Master"]
  const restrictedRoles = []

  const [userData, setUserData] = useState({});
  // const [contView, setContView] = useState("Dashboard");
  const [bodyContView, setBodyContView] = useState("Dashboard");
  const [listeners, setListeners] = useState([]);
  const [roleDataMap, setRoleDataMap] = useState({});
  const [storeBranchDetailDataMap, setStoreBranchDetailDataMap] = useState({});
  const [avlbtyDataMap, setAvlbtyDataMap] = useState({});
  const [waitTimeDataMap, setWaitTimeDataMap] = useState({});
  const [storeBranchEmplDataCollMap, setStoreBranchEmplDataCollMap] = useState({});
  const [allEmployeesDataMap,setAllEmployeesDataMap]=useState({})
  const [allStoreBranchesDataMap,setAllStoreBranchesDataMap]=useState({})
  const [allStoreBrandsDataMap,setAllStoreBrandsDataMap]=useState({})
  const [allInstitutesDataMap,setAllInstitutesDataMap]=useState({})
  const [allStudentsDataMap,setAllStudentsDataMap]=useState({})
  const [allStaffDataMap,setAllStaffDataMap]=useState({})
  const [earningDataCollMap, setEarningDataCollMap] = useState({});
  const [accountDataCollMap, setAccountDataCollMap] = useState({});
  const [balancesMapColl,setBalancesMapColl]= useState([]);
  const [unpaidInvoicesMapColl,setUnpaidInvoicesMapColl]= useState([]);
  const [paidInvoicesCurMonthMapColl,setpaidInvoicesCurMonthMapColl]= useState([]); 
  const [storeBrandSignUpReqMapColl,setStoreBrandSignUpReqMapColl]=useState([])
  // const [storeBranchInputInfos,setStoreBranchInputInfos]= useState([]);
  const [extraMode,setExtraMode]=useState("Stats");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMap, setDialogMap] = useState({
    mode:"",
    title:"",
    message:"",
  });
  const [modalMap,setModalMap] = useState({})
  const [showAppInfoPrompt,setShowAppInfoPrompt] = useState(false)

  const [showPrompt, setShowPrompt] = useState(false);
  const [showPromptConf, setShowPromptConf] = useState(false);
  const [promptMap, setPromptMap] = useState({});
  const [sMap, setSMap] = useState({});
  const [dashSchemeMap, setDashSchemeMap] = useState({});
  const [dashSchemeDataMap, setDashSchemeDataMap] = useState({});
  const [adminGenDataMap,setAdminGenDataMap] = useState({})
  const [adminAccGenDataMap,setAdminAccGenDataMap] = useState({})
  const [listMap, setListMap] = useState({});
  const [listTopic, setListTopic] = useState("");
  const [inputInfos,setInputInfos] = useState([])
  const [currentOrdersMap, setCurrentOrdersMap] = useState({});
  const [ongoingOrdersMap, setOngoingOrdersMap] = useState({});
  const [completedOrdersMap, setCompletedOrdersMap] = useState({});
  const [acceptedOrdersMap, setAcceptedOrdersMap] = useState({});
  const [pastOrdersMap, setPastOrdersMap] = useState({});
  const [prepaidCardRequestsMap, setPrepaidCardRequestsMap] = useState({});
  const [unpaidInvoicesMap, setUnpaidInvoicesMap] = useState({});
  const [storeBrandSignUpReqMap, setStoreBrandSignUpReqMap] = useState({});
  const [appJobDataMap, setAppJobDataMap] = useState({});
  const [screenTitle, setScreenTitle] = useState("Dashboard");
  const [forceReload, setForceReload] = useState({});
  const [showModalBox, setShowModalBox] = useState(false);
  const [unAuthorizedRoleCond, setUnAuthorizedRoleCond] = useState(false);
  const [studentPresentExamMarksListMap,setStudentPresentExamMarksListMap] = useState({})
  const [studentAbsentExamMarksListMap,setStudentAbsentExamMarksListMap] = useState({})
  const [studentExamMarksListMap,setStudentExamMarksListMap] = useState({})
  const [studentExamMarksInfoMap,setStudentExamMarksInfoMap] = useState({})
  const [studentAttendanceInfoMap,setStudentAttendanceInfoMap] = useState({})


  const [curListenerDataList,setCurListenerDatList] = useState([]);

  //console.log(prepaidCardRequestMaps);

  const [signOutClicked, setSignOutClicked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const authObserver = checkUserStatus(
      navigate,
      setUserData,
      setRoleDataMap,
      setListeners,
      setShowSpinner,
      setDashSchemeMap
    );
    // const roleDataObserver = getRoleData(auth, userData, setUserData);

    return () => {
      authObserver();
      // roleDataObserver();
      console.log("Unmounted");
    };

  }, []);


  function checkUserStatus(
    navigate,
    setUserData,
    setRoleDataMap,
    setListeners,
    setShowSpinner,
    setDashSchemeMap
  ) {
    return onAuthStateChanged(auth, async (user) => {
      if (user) {

  
        setShowSpinner(true);
        const userRoleData = await getUserRole();
        const userRole = userRoleData.claims.role;

        if(restrictedRoles.includes(userRole)){
            setUnAuthorizedRoleCond(true)
            setShowModalBox(true)
        }

        const userDbType = userRole == "Institute" ? "institutes" : userRole == "Super Admin" ? "admin" : "";
      
        const userDoc = await getDoc(doc(db,`users/${user.uid}`));

        
        const userData = userDoc.data()


        const userSpecificMap = await (async()=>{

          switch(userRole){
            case "Super Admin" : return ({userUid: userData.userUid,
              userName: userData.userName})

            case "Master" : return ({
              userRoleId: "",
              userUid: "",
              userName: "",
            });

            case "Principal" : {
              
              return ({
                userRoleId: userData.userRoleId,
                userUid: userData.userUid,
                userName: userData.userName,
                userParentUid:userData.userParentUid
            })}

            case "Student" : {
              const instituteUid = await getRbaseValueByPath(`studentDatas/${userData.userUid}/instituteUid`);
              console.log(instituteUid)    
              return ({
                userRoleId: userData.userRoleId,
                userUid: userData.userUid,
                userName: userData.userName,
                userParentUid:instituteUid
            })}
            default : return(
              {
                userRoleId: userData.userRoleId,
                userUid: userData.userUid,
                userName: userData.userName,
              }
            )
          }
        // userRole == "Super Admin" ? 
        // {userUid: userData.userUid,
        //   userName: userData.userName}
        //   : userRole == "Master"? 
        // {
        //   userRoleId: "",
        //   userUid: "",
        //   userName: "",
        // }:   {
        //   userRoleId: userData.userRoleId,
        //   userUid: userData.userUid,
        //   userName: userData.userName,
        // }

      })()
        console.log(userSpecificMap)

        setUserData({
          ...user,
          role: userRole,
          dbType: userDbType,
          ...userSpecificMap
        });

  


        const val = getValue(remoteConfig,"enforce_server_functions")

        fetchAndActivate(remoteConfig).then((valA)=>{
          console.log("remoteConfig Value",getValue(remoteConfig,"enforce_server_functions").asBoolean())
        }).catch((err)=>{
          console.log("error in remoteFetch",err)
        })

        // const remoteConfigFetch = await fetchAndActivate(remoteConfig)

        // remoteConfigFetch.valueOf("only_server_functions");

        // console.log("remoteConfig Value",remoteConfigFetch.valueOf("only_server_functions"))

        const roleDocPath = await getRoleDocPath(userRole,userData);


        let roleDocListener = onSnapshot(
          doc(db, roleDocPath),
          (doc) => {
         
            setRoleDataMap({
              ...doc.data(),
            });

            setShowSpinner(false);
          }
        );


        let studentExamMarksListener = ""
        let studentAttendanceListener = ""
        let avlbtyDataListener=""
        let storeBranchEmplListener=""
        let waitTimeListener=""
        let earningDataListener=""
        let storeBranchListener=""
        let pastOrdersRequestListener =""
        let prepaidCardRequestNoListener = ""
        let currentOrdersRequestListener = ""
        let acceptedOrdersRequestListener = ""
        let appJobDataListener = ""
        let adminGenDataListener = ""
        let adminAccGenDataListener = ""
        let allStoreBranchesDataListener=""
        let allStoreBrandsDataListener=""
        let allEmployeesDataListener=""
        let unpaidInvoicesDataListener=""
        let paidInvoicesCurMonthDataListener=""
        let storeBrandSignUpRequestDataListener=""
        let allInstitutesDataListener=""
        let allStudentsDataListener=""
        let allStaffDataListener=""




        if(userRole=="Super Admin"){
          const allInstitutesDetailRef = rbaseRef(
            database,
            `institutes`
          )

          allInstitutesDataListener = onValue(allInstitutesDetailRef, (snapshot) => {
            const data = snapshot.val();
            if(snapshot.val() && snapshot.val().length!=0){
              setAllInstitutesDataMap(data);
            }           
          });

          const allStudentsDetailRef = rbaseRef(
            database,
            `studentDatas`
          )

          allStudentsDataListener = onValue(allStudentsDetailRef, (snapshot) => {
            const data = snapshot.val();
            if(snapshot.val() && snapshot.val().length!=0){
              setAllStudentsDataMap(data);
            }           
          });

          adminGenDataListener = onSnapshot(doc(db,`admin/general`),docSnapshot => {
            setAdminGenDataMap(docSnapshot.data());
          });    

          adminAccGenDataListener = onSnapshot(doc(db,`admin/accounts`),docSnapshot => {
            setAdminAccGenDataMap(docSnapshot.data());
          }); 

        }else if(userRole=="Institute" || userRole=="Principal"){

          const instituteUid = userRole=="Institute" ? userSpecificMap.userUid : userSpecificMap.userParentUid;

          
          const allStudentsDataQuery = rbaseQuery (rbaseRef(database,`studentDatas`),orderByChild('instituteUid'),equalTo(instituteUid))


          allStudentsDataListener = onValue(allStudentsDataQuery, (snapshot) => {
            const data = snapshot.val();
            if(snapshot.val() && snapshot.val().length!=0){
              setAllStudentsDataMap(data);
            }           
          });

          const allStaffDataQuery = rbaseQuery (rbaseRef(database,`staff/${instituteUid}`),orderByChild('instituteUid'),equalTo(instituteUid))

          allStaffDataListener = onValue(allStaffDataQuery, (snapshot) => {
            const data = snapshot.val();
            if(snapshot.val() && snapshot.val().length!=0){
              setAllStaffDataMap(data);
            }           
          });


      }else if(userRole=="Student"){

            const instituteUid = userSpecificMap.userParentUid;
            const studentDoc = await getDoc(doc(db,`institutes/${instituteUid}/students/${userSpecificMap.userUid}`));

            const studentData = studentDoc.data()

            console.log(instituteUid,studentData)

            const q1 = query(
              collection(db,`institutes/${instituteUid}/examMarks`),
              where("className","==",studentData.className),
              where("sectionName","==",studentData.sectionName),
              where("instituteAcademicYear","==",studentData.instituteAcademicYear)
            );

            // const querySnapshot = await getDocs(q1);

            // const snapshot = await getAggregateFromServer(
            //   collection(db,`institutes/${instituteUid}/examMarks`),
            //   {averageMarks:average(`studentExamMarksMap.${studentData.studentUid}`)})

            // console.log(snapshot.data());

      //For Current Orders
        studentExamMarksListener = onSnapshot(q1, (querySnapshot) => {
         
          const nListMap = {};
          const nListDatas = []; // to hold present student exam marks map
          const nListDatas2 = []; // To hold completed orders

          const marksInfoMap = {
            examConductedCount:querySnapshot.size,
            examAttendedCount:0,
            avgMarks:0,
            avgMarksData:[],
            totalMarks:0
          }

          querySnapshot.forEach((doc) => {

            const docData = doc.data();

            if(docData.examAttendedStudentUids.includes(userData.userUid)){
              marksInfoMap.examAttendedCount += 1;
              nListDatas.push({...docData,examPresentCond:true})
              marksInfoMap.totalMarks += docData.studentExamMarksMap[userData.userUid];
            }else{
              nListDatas2.push({...docData,examPresentCond:false})
            }

          });

          const avgMarksData = [marksInfoMap.totalMarks,marksInfoMap.examAttendedCount]
        
          marksInfoMap.avgMarksData = [...avgMarksData]
          marksInfoMap.avgMarks = marksInfoMap.totalMarks/marksInfoMap.examAttendedCount

          console.log(marksInfoMap)
          setStudentExamMarksInfoMap(marksInfoMap);

          setStudentPresentExamMarksListMap({
            listDatas:nListDatas
          })

          setStudentAbsentExamMarksListMap({
            listDatas:nListDatas2
          })


          setStudentExamMarksListMap({
            listDatas:nListDatas.concat(nListDatas2)
          })

          console.log(nListDatas.concat(nListDatas2))

        });



        const q2 = query(
          collection(db,`institutes/${instituteUid}/attendance`),
          where("className","==",studentData.className),
          where("sectionName","==",studentData.sectionName),
          where("instituteAcademicYear","==",studentData.instituteAcademicYear)
        );

        studentAttendanceListener = onSnapshot(q2, (querySnapshot) => {
         
          const nListMap = {};
          const nListDatas = []; // to hold present student exam marks map
          const nListDatas2 = []; // To hold completed orders

          const attendanceInfoMap = {
            classesConductedCount:0,
            classesAttendedCount:0,
            avgAttendance:0,
            avgAttendanceData:[],
          }

          querySnapshot.forEach((doc) => {

            const docData = doc.data();
            console.log(docData)

              attendanceInfoMap.classesConductedCount += docData.totalClassCount;
              attendanceInfoMap.classesAttendedCount += docData.studentAttendanceMap[userData.userUid];
              nListDatas.push(docData)

          });

          const avgAttendance = getPercentage(attendanceInfoMap.classesAttendedCount,attendanceInfoMap.classesConductedCount,2)

          const avgAttendanceData = [avgAttendance,attendanceInfoMap.classesConductedCount]
        
          attendanceInfoMap.avgAttendanceData = [...avgAttendanceData]
          attendanceInfoMap.avgAttendance = avgAttendance
          attendanceInfoMap.listDatas = nListDatas

          console.log(attendanceInfoMap)
          setStudentAttendanceInfoMap(attendanceInfoMap);

        });

        }

 
        const nSchemeMap = (
          function (){
            switch(userRole){
              case  "Store Brand" : return viewDashboardStoreBrandSchemeMap
              case  "Institute" : return viewDashboardInstituteSchemeMap
              case  "Principal" : return viewDashboardPrincipalSchemeMap
              case  "Super Admin" : return viewDashboardSuperAdminSchemeMap
              case "Student" : return viewDashboardStudentSchemeMap
              case  "Master" : return viewDashboardMasterSchemeMap
              default: return viewDashboardBlankSchemeMap
            }
          })();


        setDashSchemeMap({ ...nSchemeMap });

    
        const tempListeners = [...listeners];

        if(userRole=="Institute" || userRole=="Principal"){
          tempListeners.push(roleDocListener);
          tempListeners.push(allStudentsDataListener)
          tempListeners.push(allStaffDataListener)
          // tempListeners.push(instituteStaffDataListener)
          // tempListeners.push(prepaidCardRequestNoListener);
          // tempListeners.push(currentOrdersRequestListener);
          // tempListeners.push(acceptedOrdersRequestListener);
          // tempListeners.push(pastOrdersRequestListener);
          // tempListeners.push(appJobDataListener);
          // tempListeners.push(waitTimeListener);
          // tempListeners.push(avlbtyDataListener);
          // tempListeners.push(storeBranchEmplListener);
          // tempListeners.push(earningDataListener);
        }else if(userRole=="Student"){
          tempListeners.push(roleDocListener);
          tempListeners.push(studentExamMarksListener);
          tempListeners.push(studentAttendanceListener);
        }else if(userRole=="Super Admin"){
          tempListeners.push(adminGenDataListener);
          tempListeners.push(allInstitutesDataListener);
        }
        setListeners(tempListeners);

        //Background functions

        if(userRole=="Store Branch"){
        }

      } else {
        navigate("/", { replace: true }); //if user not signed in logout;
      }
    });
  }


 function viewChange(data,userData) {

   console.log(data,userData)
   const userRole = userData.role

   //setShowSpinner(true)

    if(userRole=="Super Admin" || userRole=="Master" ){

      setBodyContView(data.fnView=="No Change"?"Dashboard":data.fnView);

      switch (data.fnName) {
        case "Add Student":
          {
            setSMap({
              schemeMap:addStudentInpSchemeMap,
              ...roleDataMap,
              fnType: "others",
              tabOptionsReqd: false,
            });
            setScreenTitle("Add Student");
            setBodyContView(data.fnView);
          }
          break;

          case "Batch Add Students":
            {
              setSMap({
                ...addStudentsBatchPreInpSchemeMap,
                ...roleDataMap,
                fnType: "others",
                tabOptionsReqd: false,
              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
            break;

          case "Manage Students":
            {
              setSMap({
                schemeMap:manageStudentsSelectionSchemeMap,
                dataMap:{
                  topic:data.fnName,
                  parentUid:userData.uid,
                  navTreeStartIndex:0
                }

              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
            break;

            
          case "Add Exam":
            {
              setSMap({
                schemeMap:addExamInpSchemeMap,
                ...roleDataMap,
                fnType: "others",
                tabOptionsReqd: false,
              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
            break;

            case "Manage Exams":
              {
                setSMap({
                  schemeMap:manageExamSelectionSchemeMap,
                  dataMap:{
                    topic:data.fnName,
                    parentUid:userData.uid,
                    navTreeStartIndex:0
                  }
  
                });
                setScreenTitle(data.fnName);
                setBodyContView(data.fnView);
              }
          break;

          case "Add Marks":
            {
              
              setSMap({
                schemeMap:addMarksSelectionSchemeMap,
                dataMap:{
                  topic:data.fnName,
                  parentUid:userData.uid,
                  navTreeStartIndex:0
                }

              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
        break;

        case "Add Subject":
          {
            setSMap({
              schemeMap:addSubjectInpSchemeMap,
              ...roleDataMap,
              fnType: "others",
              tabOptionsReqd: false,
            });
            setScreenTitle(data.fnName);
            setBodyContView(data.fnView);
          }
          break;

          case "Add Principal":
            {
              setSMap({
                schemeMap:addPrincipalInpSchemeMap,
                ...roleDataMap,
                fnType: "others",
                tabOptionsReqd: false,
              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
            break;

        case "Add Attendance":
          {
            
            setSMap({
              schemeMap:addAttendanceSelectionSchemeMap,
              dataMap:{
                topic:data.fnName,
                parentUid:userData.uid,
                navTreeStartIndex:0
              }

            });
            setScreenTitle(data.fnName);
            setBodyContView(data.fnView);
          }
      break;


        case "Add Institute":
          {
            setSMap({
              schemeMap:addInstituteInpSchemeMap,
              ...roleDataMap,
              fnType: "others",
              tabOptionsReqd: false,
            });
            setScreenTitle(data.fnName);
            setBodyContView(data.fnView);
          }
          break;

          
        case "Manage Institute":
          {
            setSMap({
              schemeMap:manageInstituteSelectionSchemeMap,
              dataMap:{
                topic:data.fnName,
                parentUid:userData.uid,
                navTreeStartIndex:0
              }

            });
            setScreenTitle(data.fnName);
            setBodyContView(data.fnView);
          }
          break;

          case "Manage User Accounts":
            {
              setSMap({
                schemeMap:studentSelectionSchemeMap,
                dataMap:{
                  topic:data.fnName,
                  parentUid:userData.uid,
                  navTreeStartIndex:0
                }

              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
            break;
        

        case "Add Super Admin":
          {
            setSMap({
              schemeMap:{...addSuperAdminInpSchemeMap},
              fnType: "others",
              tabOptionsReqd: false,
            });

            setScreenTitle(data.fnName);
            setBodyContView(data.fnView);

          }
          break;

          case "Manage Super Admin":
            {
              setSMap({
                ...addSuperAdminInputObj,
                fnType: "others",
                tabOptionsReqd: false,
              });
              setScreenTitle("Manage Super Admin");
            }
            break;

          case strMap.dshBtn.manageAccount:
            {
              setSMap({
                topic:"Manage Accounts"
              });
              //setShowModalBox(true);
              setScreenTitle("Manage Accounts");
              console.log("Manage Accounts");
            }
            break;
            case strMap.dshBtn.storeBrandSignUpRequest:
              {
                setSMap({
                  ...viewSignUpRequestAdminSchemeMap,
                  ...adminGenDataMap,
                  listViewType: "Button Strip",
                  listTopic: "signUpRequest",
                  fnName: "Sign Up Request",
                });
    
                setShowSpinner(true)
                setScreenTitle("Sign Up Requests");
                setListTopic( "signUpRequest");
              }
              break;

        default:
          setSMap({});
      }
    }else if(userRole=="Institute" || userRole=="Principal"){

      const preInputMap = {
        instituteUid:userRole=="Institute" ? userData.uid : userData.userParentUid
      }

      switch (data.fnName) {
        case "View Student":
          {
            setSMap({
              schemeMap:viewStudentsSelectionSchemeMap,
              dataMap:{
                topic:data.fnName,
                parentUid:userData.uid,
                navTreeStartIndex:1,
                initNodeValMap:{
                  ...preInputMap
                }
              }

            });
            setScreenTitle(data.fnName);
            setBodyContView(data.fnView);
          }
          break;
        case "Manage Students":
          {
            setSMap({
              schemeMap:manageStudentsSelectionSchemeMap,
              dataMap:{
                topic:data.fnName,
                parentUid:userData.uid,
                navTreeStartIndex:1,
                initNodeValMap:{
                  ...preInputMap
                }
              }

            });
            setScreenTitle(data.fnName);
            setBodyContView(data.fnView);
          }
          break;
        case "Add Attendance":
          {
            
            setSMap({
              schemeMap:addAttendanceSelectionSchemeMap,
              dataMap:{
                topic:data.fnName,
                parentUid:userData.uid,
                navTreeStartIndex:1,
                initNodeValMap:{
                  ...preInputMap
                }
              }

            });
            setScreenTitle(data.fnName);
            setBodyContView(data.fnView);
          }
      break;

      case "View Attendance":
        {
          
          setSMap({
            schemeMap:viewAttendanceSelectionSchemeMap,
            dataMap:{
              topic:data.fnName,
              parentUid:userData.uid,
              navTreeStartIndex:1,
              initNodeValMap:{
                ...preInputMap
              }
            }

          });
          setScreenTitle(data.fnName);
          setBodyContView(data.fnView);
        }
    break;

          case "Add Marks":
            {
              
              setSMap({
                schemeMap:addMarksSelectionSchemeMap,
                dataMap:{
                  topic:data.fnName,
                  parentUid:userData.uid,
                  navTreeStartIndex:1,
                  initNodeValMap:{
                    ...preInputMap
                  }
                }

              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
        break;
          case "View Marks":
            {
              setSMap({
                schemeMap:viewMarksSelectionSchemeMap,
                dataMap:{
                  topic:data.fnName,
                  parentUid:userData.uid,
                  navTreeStartIndex:1,
                  initNodeValMap:{
                    ...preInputMap
                  }
                }

              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
        break;
          }

    }else if(userRole=="Student"){

      switch (data.fnName) {
      case "View Marks":
        {
          setSMap({
            schemeMap:viewStudentMarksListAdvanceSchemeMap,
            ...roleDataMap
          });
          setScreenTitle(data.fnName);
          setBodyContView(data.fnView);
        }
      break;
      case "View Attendance":
        {
          setSMap({
            schemeMap:viewStudentAttendanceAdvanceSchemeMap,
            ...roleDataMap
          });
          setScreenTitle(data.fnName);
          setBodyContView(data.fnView);
        }
      break;
        }
    }else{ // function viewChange Shortcut for Store Branch
      setBodyContView(data.fnView== "No Change" ? "Dashboard" : data.fnView);

      const preInputMap = {
        instituteUid:userData.uid
      }

        switch (data.fnName) {
          case "Add Marks":
            {
              setSMap({
                schemeMap:addMarksSelectionSchemeMap,
                dataMap:{
                  topic:data.fnName,
                  parentUid:userData.uid,
                  navTreeStartIndex:1,
                  initNodeValMap:{
                    ...preInputMap
                  }
                }

              });
              setScreenTitle(data.fnName);
              setBodyContView(data.fnView);
            }
        break;
                    
        case "Add Exam":
          {
            setSMap({
              schemeMap:addExamInpSchemeMapInstitute,
              preInpMap:{
                ...preInputMap
              },
              ...roleDataMap,
              fnType: "others",
              tabOptionsReqd: false,
            });
            setScreenTitle("Add Exam");
            setBodyContView(data.fnView);
          }
          break;

       
              case "Firestore":
                {
                  setScreenTitle("Test Firestore");
                  setBodyContView(data.fnView);
                }
                break;

          
              
          default:
            setSMap({});
        }

      }

  }


  function getSettingsData(roleDataMap){

    console.log(roleDataMap)

    const settingsObj =
      userData.role == "Store Brand"
        ? editSettingsStoreBrandInputSchemeMap
        : userData.role == "Super Admin" ? editSettingsSuperAdminInputSchemeMap : editSettingsStoreBranchInputSchemeMap;

    console.log(settingsObj)

    // console.log("edit settings scheme",editSettingsStoreBranchInputSchemeMap)

    // const imgRequired = userData.role != "Super Admin" ? true : false;
    const tabOptionsReqd = true

    console.log(appJobDataMap);

    const avlbtyMap = {
      ...avlbtyDataMap,
      ...waitTimeDataMap,
    };

    console.log(settingsObj,avlbtyDataMap,waitTimeDataMap,roleDataMap)

    const jobSettings = userData.role != "Store Branch" ? {} : getJobSettingsData(roleDataMap.jobSettings, appJobDataMap);

    console.log(roleDataMap)

    return {
      ...settingsObj,
      ...roleDataMap,
      dataMap: [
        roleDataMap.settings, //settings map as present in the firestore database
        jobSettings, //as I am combining the app data with the store specific data
        roleDataMap.location,
        avlbtyMap,
      ],
      // imgRequired: imgRequired,
      fnType: "settings",
      tabOptionsReqd: tabOptionsReqd,
    };
  }


  function signOut() {
    console.log("signingOut");
    console.log(listeners);
    listeners.forEach((listener) => {
      listener();
    });
    auth.signOut();
  }

  async function showTime() {
    const timeServerCall = httpsCallable(functions, "currentTime");
    const time = await timeServerCall({});
    const date = new Date(time.data);
    alert(`${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`);

    //const date = new Date(time);

    console.log(time);
  }



  function actionHandle(data,userData) {
  
    setShowSpinner(true);
   // console.log(data.actionName);
    if (data.fnName == "Check Prepaid Card Request") {
      data.actionName == "Approve"
        ? assignPrepaidCardToCustomer(data.dbData)
        : deleteCustomerPrepaidCardRequest(data.dbData);
    } else if (data.fnName == "Check Current Orders") {

      switch(data.actionName){
        case "Paid":
          confirmOrderPayment(data.dbData)
          break;
          case "More Info":
            viewChange({
              fnName: "Open Current Order Details",
              fnView: "OrderDetailsActionBtnCont",
              dbData: data.dbData,
            },userData)
            break;
            case "Cancel Order":
              cancelJobOrder(data.dbData)
              break;
              case "Mark Collected":
                orderCollectionHandler(data)
                break;
            default:
              alert("No Function Assigned")
             
      }

      // data.actionName == "Paid"
      //   ? confirmOrderPayment(data.dbData)
      //   :    data.actionName == "More Info" ? viewChange({
      //       fnName: "Open Current Order Details",
      //       fnView: "OrderDetailsActionBtnCont",
      //       dbData: data.dbData,
      //     },userData) : orderCollectionHandler(data);
    } else if (data.fnName == "Check Accepted Orders") {
      data.actionName == "Paid" ? confirmOrderPayment(data.dbData)
      : viewChange({
            fnName: "Open Accepted Order Details",
            fnView: "OrderDetailsActionBtnCont",
            dbData: data.dbData,
          },userData)
        ;
    }  else if (data.fnName == "Check Current Order Details") {
     viewChange({
      fnName: "Show QR",
      fnView: "ModalBox",
     })
    } else if (data.fnName == "Check Past Orders") {
  
      viewChange({
            fnName: "Open Past Order Details",
            fnView: "OrderDetailsCont",
            dbData: data.dbData,
            pageNo: data.pageNo
          },userData)
        
    }else if (data.fnName == "Manage Prepaid Scheme") {
      prepaidSchemeFn(data.actionName, data.dbData);
    }
  }

  async function prepaidSchemeFn(actionName, dbData) {
    if (actionName == "Delete") {
      console.log(dbData);
      deleteDoc(
        doc(
          db,
          `storeBranches/${roleDataMap.storeBranchUid}/prepaidSchemes/${dbData.docId}`
        )
      ).then(() => {


        displayPrompt("Prepaid Scheem Delete","Prepaid Scheme Successfuly Deleted",true)
        const map = {};
        setForceReload({ ...map });
      });
    } else if (actionName == "Hold") {
      updateDoc(
        doc(
          db,
          `storeBranches/${roleDataMap.storeBranchUid}/prepaidSchemes/${dbData.docId}`
        ),
        {
          prepaidSchemeStatus: "hold",
          docModificationDate: serverTimestamp(),
        }
      ).then(() => {
        alert("Prepaid Scheme Disabled Successfully");
        const map = {};
        setForceReload({ ...map });
      });
    } else if (actionName == "Release") {
      updateDoc(
        doc(
          db,
          `storeBranches/${roleDataMap.storeBranchUid}/prepaidSchemes/${dbData.docId}`
        ),
        {
          prepaidSchemeStatus: "live",
          docModificationDate: serverTimestamp(),
        }
      ).then(() => {
        alert("Prepaid Scheme Enabled Successfully");
        const map = {};
        setForceReload({ ...map });
      });
    }
    setShowSpinner(false);
  }

  async function assignPrepaidCardToCustomer(dbData) {
    console.log("Entry");
    const assignCall = httpsCallable(functions, "assignPrepaidCardToCustomer");
    const result = await assignCall(dbData);

    displayPrompt("Info",result.data,true)
    setShowSpinner(false);
  }

  async function deleteCustomerPrepaidCardRequest(dbData) {
    console.log(dbData);

    const assignCall = httpsCallable(
      functions,
      "deletePrepaidCardCustomerRequest"
    );
    const result = await assignCall(dbData);
    displayPrompt("Info",result.data,true)
    setShowSpinner(false);
  }


  async function cancelJobOrder(data){
    const cloudFnCall = httpsCallable(functions, "cancelJobOrder");
    const result = await cloudFnCall(data);
    displayPrompt("Info",result.data,true)
    console.log(result.data);
    setShowSpinner(false);
  }



  async function confirmOrderPayment(dbData) {
    console.log(dbData);
    console.log(roleDataMap);

    dbData.orderStageResponsiblePersonnelUid = dbData.orderStoreBranchUid;
    dbData.orderStageResponsiblePersonnelName = dbData.orderStoreFullName;
    console.log(dbData);

    if (dbData.orderStatus=="accepted" || dbData.orderStatus=="jobStarted"){
      const cloudFnCall = httpsCallable(functions, "markPaidOrder");
      const result = await cloudFnCall(dbData);
      displayPrompt("Info",result.data,true)
      console.log(result.data);
      setShowSpinner(false);
    }else if(dbData.orderStatus=="pendingPayment"){

      const customerUid = dbData.orderCustomerUid;

      const customerDoc = await getDoc(doc(db, `customers/${customerUid}`));
      const customerNoAppCond = customerDoc.data().customerNoAppCond;
      
       let result = "";


      // if(customerNoAppCond){
        console.log("dbData is",dbData)
        const cloudFnCall = httpsCallable(functions, "markPaidOrder");
        result = await cloudFnCall(dbData);
      // }else{
      //   const cloudFnCall = httpsCallable(functions, "storeProgressJobOrder");
      //   result = await cloudFnCall(dbData);
      // }

      displayPrompt("Info",result.data,true)
      console.log(result.data);
      setShowSpinner(false);
    }
  }

  async function orderCollectionHandler(data){

    console.log(data)


    if(data.actionName=="Send Collection Notification"){

    const notifPayload = {
      title:"Confirm Skate Collection",
      messageBody:`It seems your skates are still at ${data.dbData.orderStoreFullName}. If you’ve already picked them up please tap to enter the app and mark as collected. If you are still waiting for a convienent time to pick up the skate please ignore this message`,
      customerUid:data.dbData.orderCustomerUid,
      storeBranchUid:data.dbData.orderStoreBranchUid,
      orderDocUid:data.dbData.docUid
    }

    const cloudFnCall = httpsCallable(functions,"sendOrderCollectionNotification");
    const result = await cloudFnCall(notifPayload);
    displayPrompt("Info",result.data,true)
    console.log(result.data);
    setShowSpinner(false);


  }else if(data.actionName=="Mark Collected"){

    setShowSpinner(false);

    const confirmationMessage = "Is the customer picking up the skate?";



    const opFn = async ()=>{
    
      const payload = {
        ...data.dbData
      }

      setShowSpinner(true);

      const cloudFnCall = httpsCallable(functions,"collectSkateByStore");

      const result = await cloudFnCall(payload);
      displayPrompt("Info",result.data,true)
      setShowSpinner(false);

  }

    getConfirmationAndSubmit(confirmationMessage,opFn)

    // setPromptMap({
    //   title:"Skate Collection",
    //   message:"Is the customer picking up the skate?",
    //   opBtn:"Yes",
    //   opFn:opFn
    // })

    // setShowPromptConf(true)
  }


  }


  function getNotificationInsertedSchemeMap(
    schemeMap,
    fnBtnName,
    notificationCount
  ) {
    const tSchemeMap = schemeMap;
    const fnNotifications = schemeMap["fnNotifications"];
    const fnBtnNames = schemeMap["fnBtnNames"];

    console.log(fnBtnNames);

    fnBtnNames.every((array, index) => {
      if (array.indexOf(fnBtnName) != -1) {
        fnNotifications[index][array.indexOf(fnBtnName)] = notificationCount;
        return false; //alias for break statement in forEach Loop
      }
      return true;
    });

    tSchemeMap["fnNotifications"] = fnNotifications;

    return tSchemeMap;
  }


  console.log(listMap);


  function getListenerListDataArray(listName,listDataMapColl){

    console.log(listDataMapColl);

    for (const [i,listDataMap] of listDataMapColl.entries()){
      //listDataMaps is function 'arguments' without the first element i.e. dashShcemeMap, so that we get only the list maps

      if("listName" in listDataMap){
        if(listDataMap["listName"] == listName){
          console.log(listDataMap.listDataArray)
          return listDataMap.listDataArray;
        }
      }

    }
  
  }


  function getListDataArray(
    listTopic,
    currentOrdersMap,
    acceptedOrdersMap,
    pastOrdersMap,
    prepaidCardRequestsMap
  ) {
    switch (listTopic) {
      case "currentOrder":
        return currentOrdersMap["listDataArray"];
        break;
      case "acceptedOrder":
        return acceptedOrdersMap["listDataArray"];
        break;
      case "pastOrder":
        return pastOrdersMap["listDataArray"];
        break;
      case "prepaidCardRequest":
        return prepaidCardRequestsMap["listDataArray"];
        break;
    }
  }


  function submitProcessFn(moduleTopic,state){

    setShowSpinner(state)

  }

  
function dialogBtnClicked(data){

  console.log(data)
  
  if(data=="Ok"){
    setShowDialog(false)
  }
  
  }

  function toggleExtras(mode){

  }

  function subViewFn(showCond,subViewType,subViewData,moduleTopic){

    console.log(showCond,subViewType,subViewData)

    switch(subViewType){
      case "modal":{
        if(showCond){
          const modalMap = {
            type:subViewData.modalType,
            title:subViewData.modalTitle,
            dataMap:subViewData.dataMap,
            schemeMap:getModalInteractiveSchemeMap(moduleTopic)
          }
          setModalMap(modalMap)
        }else{
          setModalMap({})
        }
        setShowModalBox(showCond);
      }
      break;

  }
}



function getConfirmationAndSubmit(confirmationMessage,opFn){

  setShowPromptConf(true)

  setPromptMap({
    message:confirmationMessage,
    opFn:opFn
  });

}

//function responsible to add the counter badge on the function buttons of the dashboard
  function getDashSchemeDataMap(
    dashSchemeMap,
    currentOrdersMap,
    acceptedOrdersMap,
    prepaidCardRequestsMap,
    unpaidInvoicesMap,
    storeBrandSignUpReqMap
  ) {

    console.log(currentOrdersMap);
    console.log(arguments[0]);
    console.log(arguments[2]);

    console.log("unpaid Invoice map is",unpaidInvoicesMap)
    
    let listDataMaps = [...arguments];

    //Step: Eliminate the DashSchemeMap Argument from the list of arguments
    listDataMaps = listDataMaps.filter((it, i) => {
      return i != 0; //Skipping the first index as it is the dashSchemeMap of the argument Array
    });

    console.log(listDataMaps);

    const dashDataMap = { ...dashSchemeMap };
    const fnNotificationsGroupArray = dashSchemeMap.fnNotifications;
    const fnBtnNamesGroup = dashSchemeMap.fnBtnNames;

    console.log("Dash Data Map is =",dashDataMap);

    listDataMaps.forEach((listDataMap, i) => {
      //listDataMaps is function 'arguments' without the first element i.e. dashShcemeMap, so that we get only the list maps

      if (Object.keys(listDataMap).length != 0) {
        const curListNotificationCount = listDataMap.listDataArray.length;
        const fnBtnName = listDataMap["listName"];

        fnBtnNamesGroup.every((fnBtnNames, groupIndex) => {
          if (fnBtnNames.indexOf(fnBtnName) != -1) {
            fnNotificationsGroupArray[groupIndex][
              fnBtnNames.indexOf(fnBtnName)
            ] = curListNotificationCount;
            return false; //alias for break statement in forEach Loop
          }
          return true;
        });
      }
    });

    if (Object.keys(dashDataMap).length !== 0) {
      //Doing so because the dashMap is initialized in the useEffect, so it wont run in the first render which will mean I will have an empty dashmap obj, if I write to it, then in my dashboard component it will render the incomplete dashMap Obj and give me an errior
      dashDataMap["fnNotifications"] = fnNotificationsGroupArray;
    }

    return dashDataMap;
  }

  function backBtnClicked() {
    console.log("back btn clicked")
    setBodyContView("Dashboard");
    setScreenTitle("Dashboard");
  }

  function displayPrompt(title,message,status){

    setShowPrompt(true);
    setPromptMap({
      status: status ? status : false,
      title:title,
      message:message
    })
  }

  function dialogClicked(){
    console.log("Hello")
  }

  function checkEligibility(fnName,roleDataMap){

    switch(fnName){
      case strMap.dshBtn.addPpdCardScheme:{
        return roleDataMap.storeBranchInputInfos.includes("serviceAdded")
      }
    }
    return false

  }

  console.log(prepaidCardRequestsMap);
  console.log(currentOrdersMap);
  console.log(pastOrdersMap);

  ///render
  return (
    <React.Fragment>
    {unAuthorizedRoleCond && 
    <div className="h-full">
      {showModalBox && <ModalBoxMessagePrompt 
      promptClose={()=>signOut()}
      passMap={{title:"Wrong Role",
        message:"You are not authorized to use the app on this platform, please use the Mobile Version of the App"}} />}
    </div>
    }

    {!unAuthorizedRoleCond && 
    <div className="h-screen grid grid-rows-cust-2g sm:grid-rows-cust-2p">
      <UserDataContext.Provider value={{...userData,
      inputInfos:roleDataMap.storeBranchInputInfos}}>
        <TopBar
          productionMode = {productionMode}
          displayName={userData.displayName}
          viewName={screenTitle}
          dateTime=""
          infoClick={() => setShowAppInfoPrompt(true)}
          settingsClick={() => { setBodyContView("ChangePassword");
            setScreenTitle("Change Password");}}
          logoutClick={() => auth.signOut()}
        />
        {bodyContView == "Dashboard" && (
          <Dashboard
            roleDataMap={roleDataMap}
            productionMode = {productionMode}
            role={userData.role}
            dashSchemeMap={getDashSchemeDataMap(
              dashSchemeMap,
              currentOrdersMap,
              acceptedOrdersMap,
              prepaidCardRequestsMap,
              unpaidInvoicesMap,storeBrandSignUpReqMap
            )}
            dataMap={
              {
              allInstitutesMap:allInstitutesDataMap,
              allStudentsMap:allStudentsDataMap,
              allStaffDataMap:allStaffDataMap,         
              storeBrandSignUpReqMapColl:storeBrandSignUpReqMapColl,
              studentExamMarksInfoMap:studentExamMarksInfoMap,
              studentAttendanceInfoMap:studentAttendanceInfoMap
            }
            }
            viewChangeHandler={(data) => viewChange(data,userData)}
          />
        )}

        {bodyContView == "InputCont" && (
          <InputCont
            // view={contView}
            passMap={sMap}
            viewChangeHandler={(data) => viewChange(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "InputWithControllerContInsertDb" && (
          <InputWithControllerContInsertDb
            inpSchemeMap={sMap.schemeMap}
            preInpMap={sMap.preInpMap ? sMap.preInpMap : {}}
            passMap={sMap}
            viewChangeHandler={(data) => viewChange(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}
      


      {bodyContView == "BatchInputContStudents" && (
          <BatchInputContStudents
            // view={contView}
            schemeMap={sMap}
            viewChangeHandler={(data) => viewChange(data)}
            backBtnClickHandler={() => backBtnClicked()}
            submitProcessHandler={(moduleTopic,state)=>submitProcessFn(moduleTopic,state)}
            displayPromptHandler={(type,message,status)=> displayPrompt(type,message,status)}
          />
        )}

{bodyContView == "SpreadsheetBatchInputCont" && (
          <SpreadsheetBatchInputCont
            // view={contView}
            schemeMap={sMap}
            viewChangeHandler={(data) => viewChange(data)}
            backBtnClickHandler={() => backBtnClicked()}
            submitProcessHandler={(moduleTopic,state)=>submitProcessFn(moduleTopic,state)}
            displayPromptHandler={(type,message,status)=> displayPrompt(type,message,status)}
          />
        )}
        
        {bodyContView == "InputTabCont" && (
          <InputTabCont
            // view={contView}
            passMap={sMap}
            viewChangeHandler={(data) => viewChange(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

      {bodyContView == "SettingsTabCont" && (
          <SettingsTabCont
            // view={contView}
            passMap={getSettingsData(roleDataMap)}
            viewChangeHandler={(data) => viewChange(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "ChangePassword" && (
          <ChangePassword
            // view={contView}
            passMap={getSettingsData(roleDataMap)}
            viewChangeHandler={(data) => viewChange(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

      {bodyContView == "OrderDetailsCont" && (
          <DetailsCont
            // view={contView}
            passMap={sMap}
            actionBtnHandler={(data)=> actionHandle(data)}
           // viewChangeHandler={(data) => viewChange(data,userData)}
            backBtnClickHandler={(data) => viewChange(data,userData)}
          />
        )}

        {bodyContView == "OrderDetailsActionBtnCont" && (
          <DetailsActionBtnCont
            // view={contView}
            passMap={sMap}
            actionBtnHandler={(data)=> actionHandle(data)}
           // viewChangeHandler={(data) => viewChange(data,userData)}
            backBtnClickHandler={(data) => viewChange(data,userData)}
          />
        )}

{/* {bodyContView == "OrderDetailsActionBtnCont" && (
          <DetailsActionBtnCont
            passMap={sMap}
            actionHandler={(data)=>actionHandle({...data,fnName:"Check Order Details"},userData)}
            viewChangeHandler={(data) => viewChange(data,userData)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )} */}


        {bodyContView == "ListCont" && (
          <ListCont
            // view={contView}
            passMap={sMap}
            passListDataArray={getListDataArray(
              listTopic,
              currentOrdersMap,
              acceptedOrdersMap,
              pastOrdersMap,
              prepaidCardRequestsMap
            )}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "BannerListListenerCont" && (
          <BannerListListenerCont
              key={`${new Date().getTime()}`}
              passMap={sMap}
              stopSpinner={()=>setShowSpinner(false)}
              passListDataArray={getListDataArray(
                listTopic,
                currentOrdersMap,
                acceptedOrdersMap,
                pastOrdersMap,
                prepaidCardRequestsMap
              )}
              actionHandler={(data) => actionHandle({
                ...data,
                fnName:"Check Current Orders"}
                ,userData)}
              backBtnClickHandler={() => backBtnClicked()}
          />
        )}

{bodyContView == "BannerListListenerCont2" && (
          <BannerListListenerCont2
              key={`${new Date().getTime()}`}
              passMap={sMap}
              stopSpinner={()=>setShowSpinner(false)}
              firstListDataArray = {ongoingOrdersMap["listDataArray"]} 
              secondListDataArray = {completedOrdersMap["listDataArray"]} 
              actionHandler={(data) => actionHandle({
                ...data,
                fnName:"Check Current Orders"}
                ,userData)}
              backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "BannerListCont" && (
          <BannerListCont
            key={`${new Date().getTime()}`}
            passMap={sMap}
            listTopic={listTopic}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}


        {bodyContView == "BannerListFilterCont" && (
          <BannerListFilterCont
            // view={contView}
            passMap={sMap}
            passListDataArray={getListDataArray(
              listTopic,
              currentOrdersMap,
              acceptedOrdersMap,
              pastOrdersMap,
              prepaidCardRequestsMap
            )}
            listTopic={listTopic}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "ListCont2" && (
          <ListCont2
            // view={contView}
            passMap={sMap}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "FnSelectCont" && (
          <FnSelectCont
            // view={contView}
            passMap={sMap}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "FnSelectCont2" && (
          <FnSelectCont2
            // view={contView}
            passMap={sMap}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {/* {bodyContView == "FnSelectCont3" && (
          <FnSelectCont3
            // view={contView}
            passMap={sMap}
            listenMaps={{
              storeBranchesMap:allStoreBranchesDataMap,
              storeBrandsMap:allStoreBrandsDataMap,
              employeesMap:allEmployeesDataMap
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "FnSelectCont5" && (
          <FnSelelectCont5
            // view={contView}
            passMap={sMap}
            listenMaps={{
              storeBranchesMap:allStoreBranchesDataMap,
              storeBrandsMap:allStoreBrandsDataMap,
              employeesMap:allEmployeesDataMap
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )} */}

{bodyContView == "FnSelectCont6" && (
          <FnSelelectCont6
            // view={contView}
            schemeMap={sMap.schemeMap}
            passMap={sMap.dataMap}
            mapOfMaps={allInstitutesDataMap}
            listenMaps={{
              institutesMap:allInstitutesDataMap,
              storeBrandsMap:allStoreBrandsDataMap,
              employeesMap:allEmployeesDataMap
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

      {bodyContView == "FnSelectFilterCont" && (
          <FnSelelectFilterCont
            // view={contView}
            schemeMap={sMap.schemeMap}
            passMap={sMap.dataMap}
            mapOfMaps={allInstitutesDataMap}
            listenMaps={{
              institutesMap:allInstitutesDataMap,
              storeBrandsMap:allStoreBrandsDataMap,
              employeesMap:allEmployeesDataMap
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        
      {bodyContView == "FnSelectFilterCont2" && (
          <FnSelelectFilterCont2
            schemeMap={sMap.schemeMap}
            passMap={sMap.dataMap}
            mapOfMaps={allInstitutesDataMap}
            listenMaps={{
              institutesMap:allInstitutesDataMap,
              storeBrandsMap:allStoreBrandsDataMap,
              employeesMap:allEmployeesDataMap
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

      {bodyContView == "FnSelectFilterContAttendance" && (
          <FnSelelectFilterContAttendance
            schemeMap={sMap.schemeMap}
            passMap={sMap.dataMap}
            mapOfMaps={allInstitutesDataMap}
            listenMaps={{
              institutesMap:allInstitutesDataMap,
              storeBrandsMap:allStoreBrandsDataMap,
              employeesMap:allEmployeesDataMap
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}
              {bodyContView == "FnSelectFilterContStudents" && (
          <FnSelelectFilterContStudents
            schemeMap={sMap.schemeMap}
            passMap={sMap.dataMap}
            mapOfMaps={allInstitutesDataMap}
            listenMaps={{
              institutesMap:allInstitutesDataMap,
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

{bodyContView == "FnSelectFilterContOthers" && (
          <FnSelelectFilterContOthers
            schemeMap={sMap.schemeMap}
            passMap={sMap.dataMap}
            mapOfMaps={allInstitutesDataMap}
            
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}



{bodyContView == "FnSelectFilterContMarks" && (
          <FnSelelectFilterContMarks
            schemeMap={sMap.schemeMap}
            passMap={sMap.dataMap}
            mapOfMaps={allInstitutesDataMap}
            listenMaps={{
              institutesMap:allInstitutesDataMap,
              storeBrandsMap:allStoreBrandsDataMap,
              employeesMap:allEmployeesDataMap
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

{bodyContView == "FnSelelectContManageInstitute" && (
          <FnSelelectContManageInstitute
            // view={contView}
            schemeMap={sMap.schemeMap}
            passMap={sMap.dataMap}
            mapOfMaps={allInstitutesDataMap}
            listenMaps={{
              institutesMap:allInstitutesDataMap,
              storeBrandsMap:allStoreBrandsDataMap,
              employeesMap:allEmployeesDataMap
            }
            }
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}
        {bodyContView == "StripListFilterContViewMarks" && (
          <StripListFilterContViewMarks
            stripValueMapSource="parent"
            schemeMap={sMap.schemeMap}
            passMap={sMap}
            pageStripValueMapColl={studentExamMarksListMap.listDatas}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "StripListFilterContViewAttendance" && 
                  <StripListFilterContViewAttendance
                  stripValueMapSource="parent"
                  schemeMap={sMap.schemeMap}
                  passMap={sMap}
                  pageStripValueMapColl={studentAttendanceInfoMap.listDatas}
                  stopSpinner={()=>setShowSpinner(false)}
                  actionHandler={(data) => actionHandle(data)}
                  backBtnClickHandler={() => backBtnClicked()}
                />
        }
        {bodyContView == "StripListFilterCont" && (
          <StripListFilterCont
            // view={contView}
            passMap={sMap}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}
        
        {bodyContView == "StripListFilterCont2" && (
          <StripListFilterCont2
            // view={contView}
            passMap={sMap}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}  

{bodyContView == "StripListFilterCont3" && (
          <StripListFilterCont3
            passMap={sMap}
            listTopic={listTopic}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}  

{bodyContView == "StripListCont" && (
          <StripListCont
            // view={contView}
            passMap={sMap}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

{bodyContView == "StripListBtnCont" && (
          <StripListBtnCont
            // view={contView}
            passMap={sMap}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "ButtonStripListCont" && (
          <ButtonStripListCont
            // view={contView}
            passMap={sMap}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "ChartCont" && (
          <ChartCont
            // view={contView}
            passMap={sMap}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

        {bodyContView == "ChartCont2" && (
          <ChartCont2
            // view={contView}
            passMap={sMap}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

    {bodyContView == "ChartCont3" && (
          <ChartCont3
            // view={contView}
            passMap={sMap}
            startSpinner={()=>setShowSpinner(true)}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

{bodyContView == "ChartCont4" && (
          <ChartCont4
            // view={contView}
            passMap={sMap}
            startSpinner={()=>setShowSpinner(true)}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}
        {bodyContView == "ChartCont5" && (
          <ChartCont5
            // view={contView}
            passMap={sMap}
            startSpinner={()=>setShowSpinner(true)}
            stopSpinner={()=>setShowSpinner(false)}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

{bodyContView == "ButtonFilterStripListCont" && (
          <ButtonFilterStripListCont
            // view={contView}
            passMap={sMap}
            listTopic={listTopic}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

{bodyContView == "FilterStripListCont" && (
          <FilterStripListCont
            // view={contView}
            passMap={sMap}
            listTopic={listTopic}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

      {bodyContView == "StripListFilterBtnCont" && (
                <StripListFilterBtnCont
                  // view={contView}
                  passMap={sMap}
                  listTopic={listTopic}
                  startSpinner={()=>setShowSpinner(true)}
                  stopSpinner={()=>setShowSpinner(false)}
                  actionHandler={(data) => actionHandle(data)}
                  backBtnClickHandler={() => backBtnClicked()}
                />
      )}

      
{bodyContView == "StripListFilterBtnCont2" && (
                <StripListFilterBtnCont2
                  // view={contView}
                  passMap={sMap}
                  listTopic={listTopic}
                  stopSpinner={()=>setShowSpinner(false)}
                  actionHandler={(data) => actionHandle(data,userData)}
                  backBtnClickHandler={() => backBtnClicked()}
                />
      )}

{bodyContView == "StripListFilterBtnCont3" && (
                <StripListFilterBtnCont3
                  // view={contView}
                  passMap={sMap}
                  listTopic={listTopic}
                  stopSpinner={()=>setShowSpinner(false)}
                  actionHandler={(data) => actionHandle(data,userData)}
                  backBtnClickHandler={() => backBtnClicked()}
                />
      )}

{bodyContView == "StripListBtnCont2" && (
                <StripListBtnCont2
                  passMap={sMap}
                  listTopic={listTopic}
                  startSpinner={()=>setShowSpinner(true)}
                  stopSpinner={()=>setShowSpinner(false)}
                  actionHandler={(data) => actionHandle(data)}
                  backBtnClickHandler={() => backBtnClicked()}
                />
      )}

{bodyContView == "StripListBtnListenerCont" && (
          <StripListBtnListenerCont
              key={`${new Date().getTime()}`}
              passMap={sMap}
              stopSpinner={()=>setShowSpinner(false)}
              listenerDataMapColl={getListenerListDataArray(listTopic,[storeBrandSignUpReqMap])}
              actionHandler={(data) => actionHandle({
                ...data,
                fnName:"Check Current Orders"}
                ,userData)}
              backBtnClickHandler={() => backBtnClicked()}
          />
        )}

      
{bodyContView == "RecordBannerFilterCont" && (
                <RecordBannerFilterCont
                  // view={contView}
                  passMap={sMap}
                  listTopic={listTopic}
                  stopSpinner={()=>setShowSpinner(false)}
                  actionHandler={(data) => actionHandle(data,userData)}
                  subViewHandler={(showCond,subViewType,subViewData,moduleTopic)=>subViewFn(showCond,subViewType,subViewData,moduleTopic)}
                  backBtnClickHandler={() => backBtnClicked()}
                />
      )}

        {bodyContView == "FilterStripListContStores" && (
          <FilterStripListContStores
            // view={contView}
            passMap={sMap}
            listTopic={listTopic}
            actionHandler={(data) => actionHandle(data)}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}


        
      { bodyContView == "TestContFirestore" && (
          <TestContFirestore
            // view={contView}}
            backBtnClickHandler={() => backBtnClicked()}
          />
        )}

      </UserDataContext.Provider>
      {showSpinner && <LoadSpinner/>}
      {showModalBox && modalMap.type=="modalBoxExcel" && <ModalBoxExcel passMap={sMap} closeClickHandler={()=>setShowModalBox(false)}/>}
      {showDialog && <Dialog btnClickHandler={(data)=>dialogBtnClicked(data)} passMap={dialogMap}/>}
      {showPrompt && <ModalBoxMessagePrompt passMap={promptMap} promptClose={()=>setShowPrompt(false)}/>}
      {showPromptConf && <ModalBoxOpConfPrompt passMap={promptMap} promptClose={()=>setShowPromptConf(false)}/>}
      {showModalBox && modalMap.type=="modalBoxInteractive" && 
          <ModalBoxInteractive 
            passMap={modalMap.dataMap}
            schemeMap={modalMap.schemeMap} 
            modalTitle={modalMap.title}
            btnClickHandler={(data)=>{}}
            modalClose={()=>subViewFn(false,"modal")}

          />
          }
      {showAppInfoPrompt && <ModalBoxAppInfo promptClose={()=>setShowAppInfoPrompt(false)}/>}
    </div>
    }

    </React.Fragment>
  );



function getJobSettingsData(settingsDataMap, appJobSettingsMap) {
  console.log(settingsDataMap);
  console.log(appJobSettingsMap);
  const rMap = { ...appJobSettingsMap };
  rMap["jobCosts"] = [];
  rMap["jobEstdWaitTimes"] = [];
  rMap["isJobOffered"] = [];

  rMap.jobNames.forEach((jobName, index) => {
    if (settingsDataMap.jobNames.indexOf(jobName) != -1) {
      const matchIndex = settingsDataMap.jobNames.indexOf(jobName);
      rMap.jobCosts[matchIndex] = settingsDataMap.jobCosts[matchIndex];
      rMap.jobEstdWaitTimes[matchIndex] =
        settingsDataMap.jobEstdWaitTimes[matchIndex];
      rMap.jobItemNames[matchIndex] = settingsDataMap.jobItemNames[matchIndex];
      rMap.isJobOffered[matchIndex] = true;
    } else {
      rMap.jobCosts[index] = 0;
      rMap.jobEstdWaitTimes[index] = 0;
      rMap.isJobOffered[index] = false;
    }
  });

  console.log(rMap);

  return rMap;
}

}


//bg-secondaryTransparent30
//grid-cols-cust-6v