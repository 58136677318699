import { Timestamp } from "firebase/firestore";






Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }



export function getWeekNoFromJsDate(jsDate){
    
    return  jsDate.getWeek()
  
}

export function getPercentage(numerator,denominator,decimalPlaceCount){


  return numerator ?  parseFloat(numerator/denominator * 100).toFixed(decimalPlaceCount) : 0

}


export function getMonthDateCodeFromJsDate(jsDate){

  return jsDate instanceof Date && !isNaN(jsDate.valueOf()) ? `${to2Digits(jsDate.getMonth()+1)}${to2Digits(jsDate.getDate())}` : null
  

}


export function convertJSDateStrToFbTimestampInEventObj(jsDateStrEventObj){

  console.log(jsDateStrEventObj)

  const timestampEventObj = {...jsDateStrEventObj};
  const date = new Date(jsDateStrEventObj.target.value)

  const timestamp = Timestamp.fromDate(date)
  console.log(timestamp)

  timestampEventObj.target.value = timestamp

  console.log(timestampEventObj)

  return {target:{
    value:timestamp
  }}

}

export function naturalSortMapObjByKey(mapObj,keyName){

  const mapObjEntries = Object.entries(mapObj);


  function naturalCompareMapObjEntries(a, b) {
    var ax = [], bx = [];

    a[1][keyName].replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    b[1][keyName].replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
    
    while(ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }

    return ax.length - bx.length;
}

return Object.fromEntries([...mapObjEntries].sort(naturalCompareMapObjEntries))


}




export function naturalSortMapsByKey(maps,keyName){

  function naturalCompareFileFolderMap(a, b) {
    var ax = [], bx = [];

    a[keyName].replace(/(\d+)|(\D+)/g, function(_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
    b[keyName].replace(/(\d+)|(\D+)/g, function(_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
    
    while(ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
        if(nn) return nn;
    }

    return ax.length - bx.length;
}



return [...maps].sort(naturalCompareFileFolderMap)

}


export function ifValueIsDefined(value){
   

    const exemptedValues = [false,0,""]
    
    if(!value && !exemptedValues.includes(value)){
        console.log(value,"false")
        return false
    }else{
        console.log(value,"true")
        return true
    }

}



export function createNumberArray(startNum, endNum){

    const range = endNum-startNum;
    const rArray=[]
  
    for(let x=endNum;x>=startNum;x--){
      rArray.push(x)
    }
    console.log(rArray)
    return rArray;
  }


  export function createZeroMemberArray(length){

    const rArray=[]
  
    for(let x=0;x<length;x++){
      rArray.push(0)
    }

    return rArray;
  }

  export function createBlankStringMemberArray(length){

    const rArray=[]
  
    for(let x=0;x<length;x++){
      rArray.push("")
    }

    return rArray;
  }


 export function generateFinYears(noOfYears){
    const date = new Date();
    const year = date.getFullYear();
  
    const rArr=[]
  
    for (let x=0;x<=noOfYears;x++){
  
      const startYear = year-(x+1);
      const endYear = year-x;
  
      const finYearStr = `${endYear}-${startYear.toString().substring(2)}`
  
      rArr.push(finYearStr)
    }
  
    return rArr;
  
  
  }


export function getParentFolderPath(path){


    const pathComponents = path.split("/");
    pathComponents.pop();

    console.log(path,pathComponents.join("/"))
    return pathComponents.join("/")
}



export function getNumberArray(startNum, endNum){

  const range = endNum-startNum;
  const rArray=[]

  for(let x=endNum;x>=startNum;x--){
    rArray.push(x)
  }
  console.log(rArray)
  return rArray;
}


export function checkCommonElementCountBetween2Array(array1,array2){

  let count = 0;

  array1.forEach((it)=>{
    if(array2.includes(it)){
      count++;
    }
  })

return count;

}


export function parseValueWhenApplicable(value){
  if(isJSONParseable(value)){
      return JSON.parse(value)
  }else{
    return value
  }

}


function isJSONParseable(item){
  
  if(typeof item!="string"){
    return false;
  }

  try{
    JSON.parse(item);
    return true

  }catch(error){
    return false
  }

}



    
export function flattenArray(array,uiTypes,mode){
  console.log(array)
  const rArray = []

  for(const [index,uiType] of uiTypes.entries()){

      if(uiType=="dynamic" || uiType=="dynamic-small" || uiType=="fixed-row" ||  uiType=="dynamic-multi"){
      //[["ab","ba"],["sa","ma"],["cl","ad"]]

        rArray.push(...array[index]);

      }else if(uiType=="dynamic-nested"){
          //["workCategories","expInWorkCategories",["clientNames","workDescriptions","valueOfWorkOrders","workOrderAttachments","completionCertficateAttachments","workStatuses"]],

          if(mode=="value"){
                     //[["ab","ba"],["sa","ma"],[[[],[]],[[],[]],[[],[]]]]
                      // ["ab","ba"],["sa","ma"],[[],[]],[[],[]],[[],[]]

                      console.log(array[index])

                      for(const item of array[index]){
                          if (typeof item[0] == "object"){
                              console.log(...item)
                              rArray.push(...item);
                          }else{
                              rArray.push(item);
                          }
                      }

          }else{
              rArray.push(...[].concat(...array[index]))
          }

      }else{
              rArray.push(array[index])
      }

  }

  return rArray;

}



  export function checkCandidateApplicability(){

    const profile={
      age:47,
      reservationCaste:"obc",
      reservationDisability:false,
      reservationDefence:true,
      qualificationDegree:"ITI Mechanical",
      workExperience:21
    };



    const conditions={
      ageLimit:{
        cutoffRange:545646,
        values:[40,45,50,50],
        keys:["reservationCaste","reservationCaste","reservationDisability","reservationDefence"],
        keyValues:[null,["sc/st","obc"],true,true]
      },
      qualification:{
        values:["BE Mechanical","Diploma Mechanical","ITI Mechanical"],
      },
      workExperience:{
        values:[10,20],
        keys:["qualificationDegree","qualificationDegree"],
        keyValues:[["BE Mechanical","Diploma Mechanical"],"ITI Mechanical"]
      }
    };


    let count = 0;

    const mapCheck = {
      ageLimit:getAgeLimit(profile,conditions),
      workExperience:getWorkExperience(profile,conditions),
      qualificationCond:conditions.qualification.values.includes(profile.qualificationDegree)
    }

    if(profile.age <= getAgeLimit(profile,conditions)){
      count++
    }

    if(conditions.qualification.values.includes(profile.qualificationDegree)){
      count++
    }

    if(profile.workExperience >=  getWorkExperience(profile,conditions)){
      count++;
    }


    return count==3;


    function getAgeLimit(profile,conditions){

      const applicableKeys = []

      conditions.ageLimit.keys.forEach((key,i)=>{
          const conditionValue = conditions.ageLimit.keyValues[i];

          if(conditionValue){

            if (Array.isArray(conditionValue)){

              if(conditionValue.includes(profile[key])){
                applicableKeys.push(conditions.ageLimit.values[i]);
              }

            }else{
              if(profile[key]==conditionValue){
                applicableKeys.push(conditions.ageLimit.values[i]);
              }
            }

          }else{
            applicableKeys.push(conditions.ageLimit.values[i]);
          }

      })

      return applicableKeys.sort().reverse()[0]

    };



    function getWorkExperience(profile,conditions){

      const applicableKeys = []

      conditions.workExperience.keys.forEach((key,i)=>{
          const conditionValue = conditions.workExperience.keyValues[i];
          if(conditionValue){

            if (Array.isArray(conditionValue)){
              if(conditionValue.includes(profile[key])){
                applicableKeys.push(conditions.workExperience.values[i]);
              }
            }else{
              if(profile[key]==conditionValue){
                applicableKeys.push(conditions.workExperience.values[i]);
              }
            }

          }else{
            applicableKeys.push(conditions.workExperience.values[i]);
          }

      })

      return applicableKeys.sort()[0]
    }



  };


  export function getSumOfArray(array){
    return array.reduce((accumulator,currentValue)=>accumulator+currentValue,0);
  }

  export function convertRbaseTimestampObjToFbTimestamp(rbaseTimestampObj){

    const seconds = rbaseTimestampObj._seconds
    const nanoseconds = rbaseTimestampObj._nanoseconds

    return new Timestamp(seconds,nanoseconds)

  }

  export async function getDbData(payload,url){

    console.log(payload,url)
  
    const response = await fetch(url,{
      method:"POST",
      mode:"cors",
      headers:{
        "Content-Type":"application/json"
     },
     body:JSON.stringify(payload)
  });
  
  return response.json()
  
  }



export function getDateTimeFromMySqlTimestampString(string){
  console.log(string)
  const date = new Date(string);

  return `${to2Digits(date.getDate())}-${to2Digits(date.getMonth()+1)}-${date.getFullYear()} ${to2Digits(date.getHours())}:${to2Digits(date.getMinutes())}`;
}

export function getMySqlTimeStampFromISO8601DateTimeString(iso8601DateTimeString){
  console.log(iso8601DateTimeString)
  const date = new Date(iso8601DateTimeString);

  return `${date.getFullYear()}-${to2Digits(date.getMonth()+1)}-${to2Digits(date.getDate())} ${to2Digits(date.getHours())}:${to2Digits(date.getMinutes())}:${to2Digits(date.getSeconds())}`;
}

export function getDateOnlyFromISO8601DateTimeString(iso8601DateTimeString){

  const date = new Date(iso8601DateTimeString);

  return `${date.getFullYear()}-${to2Digits(date.getMonth()+1)}-${to2Digits(date.getDate())}`;
}

export function getDateFromISO8601DateTimeString(iso8601DateTimeString){

  const date = new Date(iso8601DateTimeString);

  return `${date.getFullYear()}-${to2Digits(date.getMonth()+1)}-${to2Digits(date.getDate())}`;
}

export function getDateTimeFromISO8601DateTimeString(iso8601DateTimeString){

  const date = new Date(iso8601DateTimeString);

  return `${date.getFullYear()}-${to2Digits(date.getMonth()+1)}-${to2Digits(date.getDate())} ${to2Digits(date.getHours())}:${to2Digits(date.getMinutes())}:${to2Digits(date.getSeconds())}`;
}


export function getJSDateFromFbTimestamp(fbTimestamp){

  return fbTimestamp.toDate()

}

export function getOnlyDateFromMySqlTimestampString(string){
  console.log(string)

  const date = new Date(string);

  return `${to2Digits(date.getDate())}-${to2Digits(date.getMonth()+1)}-${date.getFullYear()}`;
}

export function getOnlyTimeFromMySqlTimestampString(string){

  const date = new Date(string);

  return `${to2Digits(date.getHours())}:${to2Digits(date.getMinutes())}`;
}


export function excelDateToJSDate(excelDate) {
  const jsDate = new Date((excelDate - 25569) * 86400000);
  return jsDate;
}


export function getDateStrFromISO8601DateTimeStr(isoDateTimeStr,outputStrFormat){

  const date = new Date(isoDateTimeStr)

  if(outputStrFormat=="mysql"){
    return `${date.getFullYear()}-${to2Digits(date.getMonth()+1)}-${to2Digits(date.getDate())}`;
  }else{
    return `${to2Digits(date.getDate())}-${to2Digits(date.getMonth()+1)}-${date.getFullYear()}`;
  }

 
}

export function getDateTimeStrFromISO8601DateTimeStr(isoDateTimeStr,outputStrFormat){

  const date = new Date(isoDateTimeStr)

  if(outputStrFormat=="mysql"){
    return `${date.getFullYear()}-${to2Digits(date.getMonth()+1)}-${to2Digits(date.getDate())} ${to2Digits(date.getHours())}:${to2Digits(date.getMinutes())}`;
  }else{
    return `${to2Digits(date.getDate())}-${to2Digits(date.getMonth()+1)}-${date.getFullYear()} ${to2Digits(date.getHours())}:${to2Digits(date.getMinutes())}`;
  }

}


export function getLaymanDateOrDateRange(startDateTime,endDateTime){

  const startJSDate = new Date(startDateTime)
  const endJSDate = new Date(endDateTime)

  console.log(startJSDate,endJSDate);

  if(getDateStrFromJSDate(startJSDate) === getDateStrFromJSDate(endJSDate)){

      return `${getMonthShort(startJSDate.getMonth())} ${startJSDate.getDate()}`

  }else{

      const startMonthShort = getMonthShort(startJSDate.getMonth())
      const endMonthShort = getMonthShort(endJSDate.getMonth())
   
      return startMonthShort==endMonthShort ? `${startMonthShort} ${startJSDate.getDate()} - ${endJSDate.getDate()}` : `${startMonthShort} ${startJSDate.getDate()} - ${endMonthShort} ${endJSDate.getDate()}`
  }

}



export function getDateStrFromJSDate(jsDate,standard){

  console.log(jsDate,standard)
    if(standard=="protocol"){
      return `${jsDate.getFullYear()}-${to2Digits(jsDate.getMonth()+1)}-${to2Digits(jsDate.getDate())}`;
    }else{
      return `${to2Digits(jsDate.getDate())}-${to2Digits(jsDate.getMonth()+1)}-${jsDate.getFullYear()}`;
    }

}





export function getMonthShort(monthIndex){
  const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
   return monthsShort[monthIndex]
}



export function embedInpValueAsEvent(inpValue){

  return {target:{
    value:inpValue
  }}
}


export function getFormFormattedValue(value){

  if(Array.isArray(value)){
    return JSON.stringify(value)
  }

  return value;
}

export function toSentenceCase(string){
  const tArr=[]
  const smallString = string.toLowerCase();
  const stringComps=  smallString.split(" ");

  stringComps.forEach((comp)=>{
      const firstCompChar = comp.split("")[0];
      tArr.push(`${firstCompChar.toUpperCase()}${comp.slice(1)}`)
  })

  return tArr.join(" ");

}


export function getTimeMainFromDateJSDate(jsDate){

  return `${jsDate.getHours()}:${jsDate.getMinutes()}`

}



export function to2Digits(number){

  if(number<10){
    return "0"+number;
  }else if(number>=10 && number <100){
    return number;
  }

}


export function generateIncrementNumArray(firstNum,lastNum){
  const rArray=[];

  for(let x=firstNum;x<=lastNum;x++){
    rArray.push(x);
  }

  return rArray

}

